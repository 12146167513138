import React, {Fragment, useCallback, useContext, useEffect, useReducer} from "react";
import {Col, Row, Progress, Button, Label, Input, FormGroup} from "reactstrap";
import numeral from "numeral";
import moment from "moment";
import {useDropzone} from "react-dropzone";
import {FaFileAlt, FaSpinner} from "react-icons/fa";
import debounce from "lodash/debounce";
import firebase from "firebase/app";
import {UserContext} from "../../contexts";

const uploadFile = (path, file, { onUpdate, onComplete }) => {
    const filename = `${moment().format("YYYYMMDDHHmmss")}-${file.name}`;
    const ref = firebase.storage().ref(path).child(filename);
    const uploadTask = ref.put(file,{
        contentType: file.type
    });
    uploadTask.on("state_changed", (state) => {
        if (onUpdate) {
            onUpdate(state);
        }
    }, (error) => {

    }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            if (onComplete) {
                onComplete(downloadURL);
            }
        });
    });
};

const reducer = (state, action) => {
    const { type, input, dispatch } = action;
    const db = firebase.firestore();
    switch (type) {
        case 'add-file':

            const newFiles = [
                ...state.files,
                input.file
            ];

            const index = newFiles.length - 1;
            dispatch({type: 'upload-file', input: {
                    classId: input.classId,
                    fileObj: input.fileObj,
                    userId: input.userId,
                    index,
                }, dispatch})

            return {
                ...state,
                files: newFiles
            };

        case 'upload-file':

            const { classId, fileObj, userId, index: fileIndex } = input;
            uploadFile(`attachments/classes/${classId}`, fileObj, {
                onUpdate: ({bytesTransferred, totalBytes}) => {

                    const progress = bytesTransferred / totalBytes * 100;
                    const uploadedFile = [...state.files][fileIndex];
                    uploadedFile.percentUploaded = progress;
                    dispatch({type: 'set-file', input: {file: uploadedFile, fileIndex}});

                },
                onComplete: (downloadURL) => {

                    const uploadedFile = [ ...state.files ][fileIndex];
                    uploadedFile.uploaded = true;
                    dispatch({ type: 'set-file', input: { file: uploadedFile, fileIndex }});

                    const newAttachment = {
                        name: uploadedFile.name,
                        size: uploadedFile.size,
                        type: uploadedFile.type,
                        downloadURL,
                        ownerId: userId,
                        created: new Date()
                    };
                    dispatch({ type: 'new-attachment', input: { classId: input.classId, attachment: newAttachment}});

                }
            });

            return {
                ...state
            };

        case 'set-file':

            const setFiles = [...state.files];
            setFiles[input.index] = input.file;

            return {
                ...state,
                files: setFiles
            };

        case 'new-attachment':

            const newAttachments = [
                ...state.attachments,
                input.attachment
            ];

            db.collection("classes").doc(input.classId).set({
                attachments: newAttachments
            }, {merge: true})
                .then(() => {
                  if (state.onUpdate) {
                      state.onUpdate(newAttachments);
                  }
                })
                .catch((error) => {
                    console.error("Error setting attachments: ", error);
                });

            return {
                ...state,
                attachments: newAttachments
            };

        case 'update-attachment':

            const updatedAttachments = [...state.attachments];
            updatedAttachments[input.index] = input.attachment;

            db.collection("classes").doc(input.classId).set({
                attachments: updatedAttachments
            }, {merge: true})
                .then(() => {
                    if (state.onUpdate) {
                        state.onUpdate(updatedAttachments);
                    }
                })
                .catch((error) => {
                    console.error("Error setting attachments: ", error);
                });

            return {
                ...state,
                attachments: updatedAttachments
            };

        case 'delete-attachment':

            let deletedAttachments = [...state.attachments];
            deletedAttachments.splice(input.index, 1);

            db.collection("classes").doc(input.classId).set({
                attachments: deletedAttachments
            }, {merge: true})
                .then(() => {
                    if (state.onUpdate) {
                        state.onUpdate(deletedAttachments);
                    }
                })
                .catch((error) => {
                    console.error("Error setting attachments: ", error);
                });

            return {
                ...state,
                attachments: deletedAttachments
            };

        case 'update-attachments':

            const updateAttachments = [...input.attachments];

            db.collection("classes").doc(input.classId).set({
                attachments: updateAttachments
            }, {merge: true})
                .catch((error) => {
                    console.error("Error setting attachments: ", error);
                });

            return {
                ...state,
                attachments: updateAttachments
            };

        case 'set-attachments':

            const setAttachments = [...input.attachments];

            return {
                ...state,
                attachments: setAttachments
            };

        default:
            throw new Error();
    }
};

const ClassAttachment = ({ classDetails, classId, onUpdate }) => {

    const user = useContext(UserContext);
    const isClassAdmin = user.hasRole('class-admin');
    const isAdminForThisClass = classDetails.admins.some(({value}) => {
        return (value === user.id);
    });

    const initialState = { attachments: classDetails.attachments, files: [], onUpdate };
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const attachments = classDetails.attachments;
        dispatch({ type: 'set-attachments', input: { attachments } });
    }, [classDetails]);

    const onDrop = useCallback(acceptedFiles => {

        acceptedFiles.forEach((file) => {
            const { name, size, type } = file;
            dispatch({ type: 'add-file',
                input: {
                    classId,
                    userId: user.id,
                    fileObj: file,
                    file: {
                        name,
                        size,
                        type,
                        percentUploaded: 0
                    }
                },
                dispatch
            });

        });
    }, [classId, user.id]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const updateAttachment = useCallback(debounce(({classId, attachment, index}) => {
        dispatch({ type: 'update-attachment', input: { classId, attachment, index }})
    }, 500), []);
    const { files, attachments } = state;

    return (
        <Fragment>
            <Row>
                <Col>
                    <h3>
                        Attachments {attachments && attachments.length > 0 ? `(${attachments.length})` : ''}
                    </h3>
                    {attachments && attachments.length > 0 &&
                        <Row className="mb-2">
                            {attachments.map((attachment, i) => {
                                const allowUpdate = isClassAdmin || isAdminForThisClass || attachment.ownerId === user.id;
                                return (
                                    <Col xs={12} key={i}>
                                        <FormGroup>
                                            <Label for={`attachment-${i+1}`}>Attachment #{i+1}</Label>
                                            <Input disabled={!allowUpdate} type="text" name={`attachment-${i+1}`} id={`attachment-${i+1}`} value={attachment.name}
                                                   onChange={(ev) => {
                                                       const newAttachments = [...attachments];
                                                       newAttachments[i].name = ev.target.value;
                                                       dispatch({ type: 'set-attachments', input: { attachments: newAttachments } });
                                                       updateAttachment({ classId, attachment: newAttachments[i], index: i});
                                                   }}
                                            />
                                            <div className="mt-2">
                                                {attachment.downloadURL &&
                                                <a href={attachment.downloadURL} target="_blank" rel="noopener noreferrer"><FaFileAlt className="align-text-top mr-2 text-primary" />{attachment.name}</a>
                                                }
                                                <small className="mx-1">({numeral(attachment.size).format("0.00b")})</small>
                                                {allowUpdate &&
                                                <Fragment>
                                                    [<Button className="p-0" color="link" onClick={() => {
                                                        dispatch({ type: 'delete-attachment', input: { classId, index: i } });
                                                    }}>Delete</Button>]
                                                </Fragment>
                                                }
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    );
                                })}
                        </Row>
                    }
                    {files && files.length > 0 &&
                        <div>
                            {files.map((file) => {
                                if (file.uploaded) {
                                    return null;
                                }
                                return (
                                    <div key={file.name}>
                                        <Fragment><FaSpinner className="align-text-top mr-2 text-primary" />{file.name}</Fragment>
                                        <small className="mx-1">({numeral(file.size).format("0.00b")})</small>
                                        <Progress striped value={file.percentUploaded} />
                                    </div>
                                );
                            })}
                        </div>
                    }
                    <div className={`file-upload ${isDragActive ? 'file-upload-drag' : ''}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        Drop some files here, or click to select files
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
};

export default ClassAttachment;
