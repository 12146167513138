import React, {Fragment, useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import {FaMinus, FaPlus} from "react-icons/fa";
import {NavLink as NavigationLink} from "react-router-dom";
import Select from "react-select";
import {
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row
} from "reactstrap";
import moment from 'moment';
import {UserContext} from "../../contexts";
import {useSettings, useUserOptions} from "../../hooks";
import {Loading} from '../../components';
import ClassAttachment from "./ClassAttachment";
import ClassSchedule from "./ClassSchedule";
import ClassScheduleDraft from "./ClassScheduleDraft";

const MAX_STUDENTS = 15;

const ClassDetail = ({ match, history }) => {

    const [insertMode, setInsertMode] = useState(false);
    const [alert, setAlert] = useState(null);

    const [type, setType] = useState({ value: '', label: ''});
    const [curriculum, setCurriculum] = useState({ value: '', label: ''});
    const [venue, setVenue] = useState("");
    const [students, setStudents] = useState([""]);
    const [teachers, setTeachers] = useState([{ value: '', label: ''}]);
    const [admins, setAdmins] = useState([{ value: '', label: ''}]);
    const [teacherHourlyRate, setTeacherHourlyRate] = useState(50);
    const [totalHours, setTotalHours] = useState(24);
    const [carryForwardHours, setCarryForwardHours] = useState(0);
    const [lessons, setLessons] = useState([]);
    const [status, setStatus] = useState(1);
    const [attachments, setAttachments] = useState([]);

    const [ssgCourseRunIdEdit, setSsgCourseRunIdEdit] = useState(false);
    const [ssgCourseRunIdDraft, setSsgCourseRunIdDraft] = useState('');
    const [ssgCourseRunId, setSsgCourseRunId] = useState('');
    const [ssgCourseRun, setSsgCourseRun] = useState(null);
    const [ssgCourseRunLastUpdateTime, setSsgCourseRunLastUpdateTime] = useState(null);
    const [ssgCourseRunSessions, setSsgCourseRunSessions] = useState(null);

    const user = useContext(UserContext);
    const isClassAdmin = user.hasRole('class-admin');
    const isAdminForThisClass = admins.some(({value}) => {
        return (value === user.id);
    });
    const isTeacherForThisClass = teachers.some(({value}) => {
        return (value === user.id);
    });

    // Only class admin can create new class
    if (insertMode && !isClassAdmin) {
        history.push({
            pathname: `/classes`
        });
    }
    // No access for this class
    if ((admins.length > 0 && admins[0].value !== '') && (teachers.length > 0 && teachers[0].value !== ''))  {
        if (!isClassAdmin && !isAdminForThisClass && !isTeacherForThisClass) {
            history.push({
                pathname: `/classes`
            });
        }
    }

    const classId = match.params.id;
    useEffect(() => {

        const insertMode = (classId === 'new');
        setInsertMode(insertMode);

        if (!insertMode) {
            const db = firebase.firestore();
            const classRef = db.collection("classes").doc(classId);
            classRef.get().then((classDoc) => {
                if (classDoc.exists) {
                    const classData = classDoc.data();
                    setType(classData.type);
                    setCurriculum(classData.curriculum);
                    setVenue(classData.venue);
                    setStudents(classData.students);
                    setTeachers(classData.teachers);
                    setAdmins(classData.admins);
                    setTotalHours(classData.totalHours);
                    setCarryForwardHours(classData.carryForwardHours);
                    setTeacherHourlyRate(classData.teacherHourlyRate);
                    setLessons(classData.lessons.map((lesson) => {
                        const lessonData = {...lesson};
                        lessonData.startTime = lessonData.startTime.toDate();
                        lessonData.endTime = lessonData.endTime.toDate();
                        return lessonData;
                    }));
                    setStatus(classData.status);
                    setAttachments(classData.attachments || []);

                    if (classData.ssgCourseRunId) {
                        setSsgCourseRunIdDraft(classData.ssgCourseRunId);
                        setSsgCourseRunId(classData.ssgCourseRunId);
                    }

                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

    }, [classId]);

    const loadCourseRun = (runId, forceReload = false) => {
        const getSSGCourseRun = firebase.functions().httpsCallable('getSSGCourseRun');
        getSSGCourseRun({ runId, forceReload }).then(({ data }) => {
            if (data.status === 'ok') {
                const courseRun = data.courseRun.data.data.course.run;
                setSsgCourseRun(courseRun);
                setSsgCourseRunLastUpdateTime(moment(data.courseRun.lastUpdateTime).toDate());
            }
        })
    };

    useEffect(() => {
        if (ssgCourseRunId) {
            loadCourseRun(ssgCourseRunId);
        }
    }, [ssgCourseRunId]);

    useEffect(() => {
        if (ssgCourseRun) {
            const { id: runId, referenceNumber: courseRefNo } = ssgCourseRun;
            const getSSGCourseRunSessions = firebase.functions().httpsCallable('getSSGCourseRunSessions');
            getSSGCourseRunSessions({ runId: String(runId), courseRefNo }).then(({ data }) => {
                if (data.status === 'ok') {
                    const courseRunSessions = data.courseRunSessions.data.data.sessions;
                    setSsgCourseRunSessions(courseRunSessions);
                }
            });
        }
    }, [ssgCourseRun])

    useEffect(() => {

        if (alert) {
            const timer = setTimeout(() => {
                setAlert(null);
            }, 2000);
            return () => {
                clearTimeout(timer);
            }
        }

    }, [ alert ]);

    const accessUserIds =  [];
    [teachers, admins].forEach((list) => {
        list.forEach(({ value }) => {
            if (value && !accessUserIds.includes(value)) {
                accessUserIds.push(value);
            }
        });
    });

    const classDetails = {
        type,
        curriculum,
        venue,
        students,
        teachers,
        admins,
        accessUserIds,
        totalHours,
        carryForwardHours,
        teacherHourlyRate,
        lessons,
        status,
        attachments
    };

    const settings = useSettings();
    const userOptions = useUserOptions();
    const teacherOptions = useUserOptions('teacher');

    const saveSSGCourseId = (ssgCourseRunId) => {
        const db = firebase.firestore();
        classDetails.ssgCourseRunId = ssgCourseRunId;

        db.collection("classes").doc(classId).set(classDetails, {merge: true})
            .then(() => {
                setSsgCourseRunIdEdit(false);
            })
            .catch((error) => {
                console.error("Error SSG Course Run ID: ", error);
            });
    }

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem tag={NavigationLink} to="/classes">Classes</BreadcrumbItem>
                { insertMode && <BreadcrumbItem active tag="span">Create new class</BreadcrumbItem> }
                { !insertMode && <BreadcrumbItem active tag="span">{curriculum.label} - {type.label}</BreadcrumbItem> }
            </Breadcrumb>
            {alert &&
            <Row>
                <Col>
                    <Alert color={alert.type}>{alert.message}</Alert>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <h3>Details</h3>
                </Col>
            </Row>
            <Form>
                <Row className="mb-2">
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="classType">Type</Label>
                            <CustomInput disabled={!isClassAdmin} value={type.value} type="select" id="classType" name="classType" onChange={(ev) => {
                                const typeValue = ev.target.value;
                                setType({
                                    value: typeValue,
                                    label: settings.classes.typeMap[typeValue]
                                });
                            }}>
                                <option value="">Select a class type</option>
                                {settings.classes && settings.classes.types.map(({label, value}) => (
                                    <option key={value} value={value}>{label}</option>
                                ))}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="classType">Curriculum</Label>
                            <CustomInput disabled={!isClassAdmin} value={curriculum.value} type="select" id="curriculumType" name="curriculumType" onChange={(ev) => {
                                const curriculumValue = ev.target.value;
                                setCurriculum({
                                    value: curriculumValue,
                                    label: settings.curriculum.typeMap[curriculumValue]
                                });
                            }}>
                                <option value="">Select a curriculum</option>
                                {settings.curriculum && settings.curriculum.types.map(({label, value}) => (
                                    <option key={value} value={value}>{label}</option>
                                ))}
                            </CustomInput>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="venue">Venue</Label>
                            <Input disabled={!isClassAdmin} type="text" name="venue" id="venue" value={venue} onChange={(ev) => setVenue(ev.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="student-0">
                                <span>Students
                                    {students.length > 1 &&
                                        <span className="ml-1">({students.length})</span>
                                    }
                                </span>
                                {(isClassAdmin || isAdminForThisClass) &&
                                    <Fragment>
                                        <Button disabled={students.length >= MAX_STUDENTS} color="primary" size="sm" className="ml-2" onClick={() => {
                                            const newStudents = [...students];
                                            newStudents.push("");
                                            setStudents(newStudents);
                                        }}><FaPlus/></Button>
                                        {students.length > 1 &&
                                            <Button color="primary" size="sm" className="ml-1" onClick={() => {
                                                const newStudents = [...students];
                                                newStudents.pop();
                                                setStudents(newStudents);
                                            }}><FaMinus /></Button>
                                        }
                                    </Fragment>
                                }
                            </Label>
                            {students.map((student, i) => (
                                <Input disabled={!isClassAdmin && !isAdminForThisClass} key={`student-${i}`} className="mb-1" type="text" name={`student-${i}`} id={`student-${i}`} value={student}
                                       onChange={(ev) => {
                                           const newStudents = [...students];
                                           newStudents[i] = ev.target.value;
                                           setStudents(newStudents);
                                       }}
                                />
                            ))}
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="teacher-0">
                                <span>Teachers
                                    {teachers.length > 1 &&
                                    <span className="ml-1">({teachers.length})</span>
                                    }
                                </span>
                                {isClassAdmin &&
                                <Fragment>
                                    <Button disabled={teachers.length >= 3} color="primary" size="sm" className="ml-2"
                                            onClick={() => {
                                                const newTeachers = [...teachers];
                                                newTeachers.push({value: '', label: ''});
                                                setTeachers(newTeachers);
                                            }}><FaPlus/></Button>
                                    {teachers.length > 1 &&
                                    <Button color="primary" size="sm" className="ml-1" onClick={() => {
                                        const newTeachers = [...teachers];
                                        newTeachers.pop();
                                        setTeachers(newTeachers);
                                    }}><FaMinus/></Button>
                                    }
                                </Fragment>
                                }
                            </Label>
                            {teachers.map((teacher, i) => (
                                <div key={`teacher-${i}`}  className="mb-1">
                                    <Select isDisabled={!isClassAdmin} options={teacherOptions} value={teacher} onChange={(teacher) => {
                                        const newTeachers = [...teachers];
                                        newTeachers[i] = teacher;
                                        setTeachers(newTeachers);
                                    }}
                                    />
                                </div>
                            ))}
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="admin-0">
                                <span>Admins
                                    {admins.length > 1 &&
                                        <span className="ml-1">({admins.length})</span>
                                    }
                                </span>
                                {isClassAdmin &&
                                <Fragment>
                                    <Button disabled={admins.length >= 3} color="primary" size="sm" className="ml-2"
                                            onClick={() => {
                                                const newAdmins = [...admins];
                                                newAdmins.push({value: '', label: ''});
                                                setAdmins(newAdmins);
                                            }}><FaPlus/></Button>
                                    {teachers.length > 1 &&
                                    <Button color="primary" size="sm" className="ml-1" onClick={() => {
                                        const newAdmins = [...admins];
                                        newAdmins.pop();
                                        setAdmins(newAdmins);
                                    }}><FaMinus/></Button>
                                    }
                                </Fragment>
                                }
                            </Label>
                            {admins.map((admin, i) => (
                                <div key={`admin-${i}`}  className="mb-1">
                                    <Select isDisabled={!isClassAdmin} options={userOptions} value={admin} onChange={(admin) => {
                                        const newAdmins = [...admins];
                                        newAdmins[i] = admin;
                                        setAdmins(newAdmins);
                                    }}
                                    />
                                </div>
                            ))}
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="totalHours">Total Hours</Label>
                            <Input disabled={!isClassAdmin} type="number" name="totalHours" id="totalHours" step="any" min="0"
                                   value={totalHours}
                                   onChange={(ev) => {
                                       const value = ev.target.value;
                                       const numberValue = Number(value);
                                       if (!isNaN(numberValue)) {
                                           setTotalHours(numberValue);
                                       }
                                   }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="carryForwardHours">Carry Forward Hours</Label>
                            <Input disabled={!isClassAdmin} type="number" name="carryForwardHours" id="carryForwardHours" step="any" min="0"
                                   value={carryForwardHours}
                                   onChange={(ev) => {
                                       const value = ev.target.value;
                                       const numberValue = Number(value);
                                       if (!isNaN(numberValue)) {
                                           setCarryForwardHours(numberValue);
                                       }
                                   }}
                            />
                        </FormGroup>
                    </Col>
                    {isClassAdmin &&
                    <Col md={6} lg={4}>
                        <FormGroup>
                            <Label for="teacherHourlyRate">Teacher Hourly Rate (SGD)</Label>
                            <Input disabled={!isClassAdmin} type="number" name="teacherHourlyRate" id="teacherHourlyRate" step="any" min="0"
                                   value={teacherHourlyRate}
                                   onChange={(ev) => {
                                       const value = ev.target.value;
                                       const numberValue = Number(value);
                                       if (!isNaN(numberValue)) {
                                           setTeacherHourlyRate(numberValue);
                                       }
                                   }}
                            />
                        </FormGroup>
                    </Col>
                    }

                </Row>
                {isClassAdmin && !insertMode &&
                    <Row className="mb-3">
                        <Col md={6} lg={4}>
                            <FormGroup>
                                <Label for="ssgCourseRunId">SSG Course Run ID</Label>
                                <InputGroup>
                                    <Input disabled={!ssgCourseRunIdEdit} type="text" name="ssgCourseRunId" id="ssgCourseRunId"
                                           value={ssgCourseRunIdDraft}
                                           onChange={(ev) => {
                                               const value = ev.target.value;
                                               setSsgCourseRunIdDraft(value)
                                           }}
                                    />
                                    {!ssgCourseRunIdEdit &&
                                        <Button color="primary" size="sm" className="ml-1" onClick={() => {
                                            setSsgCourseRunIdEdit(true);
                                        }}>Edit</Button>
                                    }
                                    {ssgCourseRunIdEdit &&
                                        <>
                                            <Button color="primary" size="sm" className="ml-1" onClick={() => {
                                                setSsgCourseRunId(ssgCourseRunIdDraft);
                                                saveSSGCourseId(ssgCourseRunIdDraft);
                                            }}>Save</Button>
                                            <Button size="sm" className="ml-1" onClick={() => {
                                                setSsgCourseRunIdEdit(false);
                                            }}>Cancel</Button>
                                        </>
                                    }
                                </InputGroup>
                            </FormGroup>
                            {ssgCourseRunId && !ssgCourseRun && <Loading />}
                            {ssgCourseRunId && ssgCourseRun && (
                                <>
                                    <div className="mb-2">Last data load: {moment(ssgCourseRunLastUpdateTime).fromNow()} [<Button className="p-0" color="link" onClick={() => {
                                            setSsgCourseRun(null);
                                            setSsgCourseRunSessions(null);
                                            loadCourseRun(ssgCourseRunId, true);
                                        }}>Refresh</Button>]
                                    </div>
                                    <div>
                                      Course run date: {moment(ssgCourseRun.courseStartDate, 'YYYYMMDD').format('D MMM YYYY')} - {moment(ssgCourseRun.courseEndDate, 'YYYYMMDD').format('D MMM YYYY')}
                                    </div>
                                    {ssgCourseRun.linkCourseRunTrainer.map(({ trainer }, i) => (
                                        <div key={i}>Registered trainer #{i+1}: {trainer.name}<br /></div>
                                    ))}
                                </>
                            )}
                        </Col>
                    </Row>
                }

                {insertMode &&
                    <ClassScheduleDraft classDetails={classDetails} onCreate={(id) => {
                        history.push({
                            pathname: `/classes/${id}`
                        });
                    }}
                    />
                }

                {!insertMode &&
                    <Fragment>
                        <ClassAttachment classDetails={classDetails} classId={classId} onUpdate={(attachments) => {
                            const newAttachments = [...attachments];
                            setAttachments(newAttachments);
                        }}
                        />
                        <ClassSchedule classDetails={classDetails} classId={classId} allowUpdate={isClassAdmin || isAdminForThisClass} courseRun={ssgCourseRun} courseRunSessions={ssgCourseRunSessions} onUpdate={(lessons) => {
                            if (lessons) {
                                window.scrollTo({ top: 0, behavior: 'smooth'});
                                const newLessons = [...lessons];
                                setLessons(newLessons);
                                setAlert({type: "success", message: "Class is updated."});
                            }
                        }}
                        />
                    </Fragment>
                }

            </Form>
        </Fragment>
    );

};

export default ClassDetail;
