import React, {Fragment, useState} from "react";
import firebase from "firebase/app";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import {Button, Col, Collapse, FormGroup, FormText, CustomInput, Input, Label, Row, Table} from "reactstrap";
import moment from "moment";

const ClassScheduleDraft = ({ classDetails, onCreate }) => {

    const newClassDetails = {...classDetails};

    const [updating, setUpdating] = useState(false);
    const [scheduleOpen, setScheduleOpen] = useState(false);

    const [lessonLengthMins, setLessonLengthMins] = useState(120);
    const [lessonStart, setLessonStart] = useState(true);
    const [lessonStartDate, setLessonStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [lessonStartTime, setLessonStartTime] = useState("09:00");

    let lessonMinutes = (newClassDetails.totalHours - newClassDetails.carryForwardHours) * 60;
    let firstLessonStartDateTime = null;
    let firstLessonEndDateTime = null;
    const lessons = [];
    newClassDetails.lessons = lessons;
    if (lessonStart && lessonStartDate && lessonStartTime && lessonLengthMins) {

        firstLessonStartDateTime = moment(`${lessonStartDate} ${lessonStartTime}`);
        firstLessonEndDateTime = moment(firstLessonStartDateTime).add(lessonLengthMins, 'minutes');

        while (lessonMinutes >= lessonLengthMins) {
            const startTime = moment(firstLessonStartDateTime).add(lessons.length * 7, 'days');
            const endTime = moment(startTime).add(lessonLengthMins, 'minutes');
            lessons.push({
                startTime: startTime.toDate(),
                endTime: endTime.toDate(),
                teacher: newClassDetails.teachers[0],
                status: 1
            });
            lessonMinutes -= lessonLengthMins;
        }

        newClassDetails.firstLessonStartTime = lessons[0].startTime;
        newClassDetails.firstLessonEndTime = lessons[0].endTime;
        newClassDetails.lastLessonStartTime = lessonMinutes === 0 ? lessons[lessons.length - 1].startTime : null;
        newClassDetails.lastLessonEndTime = lessonMinutes === 0 ? lessons[lessons.length - 1].endTime : null;

    } else {

        newClassDetails.firstLessonStartTime = null;
        newClassDetails.firstLessonEndTime = null;
        newClassDetails.lastLessonStartTime = null;
        newClassDetails.lastLessonEndTime = null;

    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <h3>Lessons</h3>
                </Col>
            </Row>
            <Row>
                <Col md={6} lg={4}>
                    <FormGroup>
                        <Label for="lessonLengthMins">Lesson length (minutes)</Label>
                        <Input type="number" name="lessonLengthMins" id="lessonLengthMins"  step="any" min="0"
                               value={lessonLengthMins}
                               onChange={(ev) => {
                                   const value = ev.target.value;
                                   const numberValue = Number(value);
                                   if (!isNaN(numberValue)) {
                                       setLessonLengthMins(numberValue);
                                   }
                               }}
                        />
                        <FormText color="muted">
                            {Math.floor(lessonLengthMins / 60)} hours
                            {lessonLengthMins % 60 > 0 &&
                            <span className="ml-1">{lessonLengthMins % 60} minutes</span>
                            }
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={6} lg={4}>
                    <FormGroup>
                        <Label for="lessonStart">First Lesson</Label>
                        <CustomInput type="switch" name="lessonStart" id="lessonStart"
                             checked={lessonStart}
                             onChange={(ev) => {
                                 const value = ev.target.checked;
                                 setLessonStart(value);
                             }}
                        />
                    </FormGroup>
                    {lessonStart &&
                    <Fragment>
                        <FormGroup>
                            <Label for="lessonStartDate">Date</Label>
                            <Input type="date" name="lessonStartDate" id="lessonStartDate" step="any"
                                   value={lessonStartDate}
                                   onChange={(ev) => {
                                       const value = ev.target.value;
                                       setLessonStartDate(value);
                                   }}
                            />
                            <FormText color="muted">
                                {lessonStartDate && moment(lessonStartDate).format('dddd, DD MMMM YYYY')}
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="lessonStartTime">Time</Label>
                            <Input type="time" name="lessonStartTime" id="lessonStartTime" step="any"
                                   value={lessonStartTime}
                                   onChange={(ev) => {
                                       const value = ev.target.value;
                                       setLessonStartTime(value);
                                   }}
                            />
                            <FormText color="muted">
                                {firstLessonStartDateTime && moment(firstLessonStartDateTime).format('hh:mm A')}
                                <span className="mx-1">-</span>
                                {firstLessonEndDateTime && moment(firstLessonEndDateTime).format('hh:mm A')}
                            </FormText>
                        </FormGroup>
                    </Fragment>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>
                        Schedule
                        <Button className="ml-2" size="sm" onClick={() => setScheduleOpen(!scheduleOpen)}>{scheduleOpen ? <FaAngleUp /> : <FaAngleDown /> }</Button>
                    </h3>
                </Col>
            </Row>
            <Collapse isOpen={scheduleOpen}>
                <Row>
                    <Col>
                        <Table striped responsive>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Duration</th>
                            </tr>
                            </thead>
                            <tbody>
                            {lessons && lessons.map(({ startTime, endTime }, i) => {
                                const startDateStr = moment(startTime).format('dddd, DD MMMM YYYY');
                                const startTimeStr = moment(startTime).format('hh:mm A');
                                const endTimeStr = moment(endTime).format('hh:mm A');
                                return (
                                    <tr key={`lesson-${i}`}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{startDateStr}</td>
                                        <td>{startTimeStr}</td>
                                        <td>{endTimeStr}</td>
                                        <td>{lessonLengthMins} minutes</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Collapse>
            <Row className="my-3">
                <Col>
                    <h6>No of lessons: {lessons.length}, Remaining: {lessonMinutes} minutes</h6>
                    <Button color="primary" disabled={updating} className="my-3" onClick={() => {
                        setUpdating(true);
                        const db = firebase.firestore();
                        db.collection("classes").add(newClassDetails)
                            .then((docRef) => {
                                setUpdating(false);
                                console.log("Document written with ID: ", docRef.id);
                                onCreate(docRef.id);
                            })
                            .catch((error) => {
                                setUpdating(false);
                                console.error("Error adding document: ", error);
                            });
                    }}>
                        <span>Create class</span>
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ClassScheduleDraft;
