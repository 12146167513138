import {useEffect, useState} from "react";
import firebase from "firebase/app";

const useSettings = () => {
    const [settings, setSettings] = useState([]);
    useEffect(() => {

        const db = firebase.firestore();
        db.collection("settings").get().then((querySnapshot) => {
            const settingsDocs = [];
            querySnapshot.forEach((doc) => {
                settingsDocs.push({id: doc.id, data: doc.data()});
            });
            setSettings(settingsDocs);
        });

    }, []);

    const settingsMap = {};
    if (settings) {
        settings.forEach(({id, data}) => {
            if (data.types) {
                const settingCategory = data.id || id;
                if (!settingsMap[settingCategory]) {
                    settingsMap[settingCategory] = {};
                }
                if (!settingsMap[settingCategory]['types']) {
                    settingsMap[settingCategory]['types'] = [];
                }
                if (!settingsMap[settingCategory]['typeMap']) {
                    settingsMap[settingCategory]['typeMap'] = {};
                }
                const typeKeys = Object.keys(data.types);
                typeKeys.forEach((key) => {
                    const label = data.types[key];
                    settingsMap[settingCategory]['types'].push({
                        label,
                        value: key
                    });
                    settingsMap[settingCategory]['typeMap'][key] = label;
                });
                settingsMap[settingCategory]['types'].sort((t1, t2) => {
                    return (t1.label < t2.label) ? -1 : (t1.label > t2.label) ? 1 : 0;
                });
            } else {
                settingsMap[id] = data;
            }
        });
    }
    return settingsMap;
};

export default useSettings;
