import React, { useState } from 'react';
import jsonp from 'jsonp';
import firebase from "firebase/app";
import {NavLink as NavigationLink} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import Select from "react-select";
import { useUserOptions } from "../../hooks";

const ProfileMenu = ({ user, onLogout }) => {

    const [showSwitchUserPrompt, setShowSwitchUserPrompt] = useState(false);
    const [selectedUser, setSelectedUser] = useState({value: user.id, label: user.name});
    const userOptions = useUserOptions();

    const toggle = () => {
        setShowSwitchUserPrompt(!showSwitchUserPrompt);
    };
    const switchUser = () => {
        if (user.id !== selectedUser.value) {
            user.switchUser(selectedUser.value);
        }
        setShowSwitchUserPrompt(false);
    };

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
                Profile
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem>
                    <NavLink
                        tag={NavigationLink}
                        to="/profile"
                        activeClassName="active"
                    >
                        {user.name}
                    </NavLink>
                </DropdownItem>
                {(user.switchUser) &&
                <DropdownItem>
                    <NavLink
                        tag={NavigationLink}
                        to="/"
                        onClick={() => {
                            setShowSwitchUserPrompt(true);
                        }}
                    >
                        Switch
                    </NavLink>
                    <Modal isOpen={showSwitchUserPrompt} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Switch User</ModalHeader>
                        <ModalBody>
                            <Select options={userOptions} value={selectedUser} onChange={(user) => {
                                setSelectedUser(user);
                            }} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={switchUser}>Switch</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </DropdownItem>
                }
                <DropdownItem divider/>
                <DropdownItem>
                    <NavLink
                        tag={NavigationLink}
                        to="/"
                        onClick={() => {
                            const auth = firebase.auth();
                            if (auth && auth.currentUser && auth.currentUser.providerData &&
                                auth.currentUser.providerData.length > 0
                                && auth.currentUser.providerData[0].providerId === 'google.com') {
                                jsonp('https://accounts.google.com/logout');
                            }
                            auth.signOut().then(() => {

                                if (onLogout) {
                                    onLogout();
                                }
                            });
                        }}
                    >
                        Logout
                    </NavLink>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ProfileMenu;
