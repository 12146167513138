import React, { Fragment, useEffect, useState} from "react";
import firebase from "firebase/app"
import {Button} from "reactstrap";
import {Loading} from "../../components";

const login = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
};

const Login = () => {

    const [ loaded, setLoaded ] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 2000);
        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <Fragment>
            {loaded &&
                <div className="my-5 text-center">
                    <Button color="primary" onClick={login}>Login with Google ID</Button>
                </div>
            }
            {!loaded &&
                <Loading />
            }
        </Fragment>
    );

};

export default Login;
