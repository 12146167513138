import React, { useEffect, useState } from "react";
import firebase from 'firebase/app';
import {BrowserRouter} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import {Header, Loading, Login} from "./components";
import {UserContext, LoginContext} from "./contexts";
import {Page} from "./routes";

const App = () => {

    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [loginId, setLoginId] = useState(null);

    useEffect(() => {

        const auth = firebase.auth();
        auth.getRedirectResult().then(({user}) => {
            if (user) {
                const db = firebase.firestore();
                db.collection("users").doc(user.uid).set({
                    id: user.uid,
                    loginName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL
                }, { merge: true });
            }
        }).catch((error) => {
            console.log("Error logging in", error);
        });

        auth.onAuthStateChanged((user) => {
            if (user) {
                const db = firebase.firestore();
                const loginId = user.uid;
                const userRef = db.collection("users").doc(loginId);
                userRef.set({
                    lastLogin: new Date()
                }, { merge: true });
                userRef.get().then((userDoc) => {
                    if (userDoc.exists) {
                        const user = userDoc.data();
                        user.loginId = loginId;

                        const currentUser = {...user};
                        if (!currentUser.name) {
                            currentUser.name = currentUser.loginName;
                        }
                        currentUser.hasRole = (role) => {
                            return user.roles && user.roles.includes(role);
                        };
                        if (currentUser.hasRole('user-admin')) {
                            currentUser.switchUser = (loginId) => {
                                const db = firebase.firestore();
                                const userRef = db.collection("users").doc(loginId);
                                userRef.get().then((userDoc) => {
                                    if (userDoc.exists) {
                                        const user = userDoc.data();
                                        user.loginId = currentUser.loginId;

                                        const impersonateUser = {...user};
                                        if (!impersonateUser.name) {
                                            impersonateUser.name = impersonateUser.loginName;
                                        }
                                        impersonateUser.hasRole = (role) => {
                                            return user.roles && user.roles.includes(role);
                                        };
                                        impersonateUser.switchUser = currentUser.switchUser;

                                        setCurrentUser(impersonateUser);
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.log("No such document!");
                                    }
                                }).catch((error) => {
                                    console.log("Error getting document:", error);
                                });

                            };
                        }

                        setCurrentUser(currentUser);
                        setLoginId(loginId);
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            } else {
                setCurrentUser(null);
                setLoginId(null);
            }
            setLoading(false);
        });
    }, []);

    return (
        <BrowserRouter>
            <LoginContext.Provider value={{
                id: loginId
            }}>
            <UserContext.Provider value={currentUser}>
                <Container>
                    <Row>
                        <Col>
                            <Header />
                        </Col>
                    </Row>
                    <Row className="pt-3" style={{
                        borderTop: '2px solid #ce1b28'
                    }}>
                        <Col>
                            {loading && <Loading/>}
                            {!loading && currentUser && <Page user={currentUser}/>}
                            {!loading && !currentUser && <Login/>}
                        </Col>
                    </Row>
                </Container>
            </UserContext.Provider>
            </LoginContext.Provider>
        </BrowserRouter>
    );

};


export default App;
