import React, { Fragment, useState } from "react";
import {NavLink as NavigationLink} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Col, CustomInput, Form, FormGroup, Label, Row} from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {Loading} from '../../components';
import {useClasses, useSettings} from "../../hooks";

moment.updateLocale('en', {
    week: {
        dow: 1
    },
});
const localizer = momentLocalizer(moment);

const formats = {
    agendaHeaderFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, 'DD MMM YYYY', culture) + ' — ' +
        localizer.format(end, 'DD MMM YYYY', culture)
};

const minTime = new Date();
minTime.setHours(7,0,0);
const maxTime = new Date();
maxTime.setHours(22,0,0);

const Schedule = ({ history }) => {

    const [type, setType] = useState({ value: 'ALL', label: 'ALL'});
    const [curriculum, setCurriculum] = useState({ value: 'ALL', label: 'ALL'});
    const [teacher, setTeacher] = useState({ value: 'ALL', label: 'ALL'});

    const events = [];

    const settings = useSettings();

    if (settings.holidays) {
        const holidays = settings.holidays;
        Object.keys(holidays).forEach((year) => {
           Object.keys(holidays[year]).forEach((monthDate) => {
               const title = holidays[year][monthDate];
               const date = moment(`${year}-${monthDate}`);

               events.push({
                   type: 'holiday',
                   title,
                   start: date.startOf('day').toDate(),
                   end: date.add(1, 'day').startOf('day').toDate(),
                   allDay: true
               });

           });
        });
    }

    let classes = useClasses(false);

    const { teachers, teacherMap } = {
        teachers: [],
        teacherMap: {}
    };
    if (classes) {
        classes.reduce((map, { teachers }) => {
            teachers.forEach((teacher) => {
                if (!map.teacherMap[teacher.value]) {
                    map.teacherMap[teacher.value] = teacher.label;
                    map.teachers.push(teacher);
                }
            });
            return map;
        }, { teachers, teacherMap });
    }
    teachers.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const types = settings.classes ? [...settings.classes.types] : [];
    if (types.length > 0) {
        types.push({
            value: 'ssg-class',
            label: 'Skillfuture Class'
        });
    }

    if (type && type.value !== 'ALL') {
        if (type.value === 'ssg-class') {
            classes = classes.filter((classObj) => {
                return !!classObj.ssgCourseRunId
            });
        } else {
            classes = classes.filter((classObj) => {
                return classObj.type.value === type.value;
            });
        }
    }
    if (curriculum && curriculum.value !== 'ALL') {
        classes = classes.filter((classObj) => {
            return classObj.curriculum.value === curriculum.value;
        });
    }
    if (teacher && teacher.value !== 'ALL') {
        classes = classes.filter((classObj) => {
            const teacherValues = classObj.teachers.map((teacher) => teacher.value);
            return teacherValues.includes(teacher.value);
        });
    }

    if (classes) {
        classes.forEach(({ id, type, curriculum, venue, lessons }) => {
            lessons.forEach((lesson, i) => {
                const teacherId = lesson.teacher.value;
                if (teacher.value === 'ALL' || teacher.value === teacherId) {
                    const teacher = lesson.teacher;
                    const title = `${curriculum.label} - ${type.label} - Lesson ${i + 1} ${venue ? `(${venue})` : ''} - ${teacher.label}`;
                    events.push({
                        id,
                        type: 'class',
                        title,
                        start: lesson.startTime.toDate(),
                        end: lesson.endTime.toDate()
                    });
                }
            });
        });
    }

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Schedule</BreadcrumbItem>
            </Breadcrumb>
            {!classes && <Loading />}
            {classes &&
            <Fragment>
                <Fragment>
                    <Form>
                        <Row className="mb-2">
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Label for="classType">Type</Label>
                                    <CustomInput value={type.value} type="select" id="classType" name="classType" onChange={(ev) => {
                                        const typeValue = ev.target.value;
                                        setType({
                                            value: typeValue,
                                            label: settings.classes.typeMap[typeValue]
                                        });
                                    }}>
                                        <option value="ALL">ALL</option>
                                        {types.map(({label, value}) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </CustomInput>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Label for="classType">Curriculum</Label>
                                    <CustomInput value={curriculum.value} type="select" id="curriculumType" name="curriculumType" onChange={(ev) => {
                                        const curriculumValue = ev.target.value;
                                        setCurriculum({
                                            value: curriculumValue,
                                            label: settings.curriculum.typeMap[curriculumValue]
                                        });
                                    }}>
                                        <option value="ALL">ALL</option>
                                        {settings.curriculum && settings.curriculum.types.map(({label, value}) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </CustomInput>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                                <FormGroup>
                                    <Label for="teacher">Teacher</Label>
                                    <CustomInput value={teacher.value} type="select" id="teacher" name="teacher" onChange={(ev) => {
                                        const teacherValue = ev.target.value;
                                        setTeacher({
                                            value: teacherValue,
                                            label: teacherMap[teacherValue]
                                        });
                                    }}>
                                        <option value="ALL">ALL</option>
                                        {teachers && teachers.map(({label, value}) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </CustomInput>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
                <Row>
                    <Col className="min-vh-100">
                        <Calendar
                            formats={formats}
                            localizer={localizer}
                            events={events}
                            min={minTime}
                            max={maxTime}
                            onSelectEvent={(event) => {
                                if (event.type === 'class') {
                                    history.push({
                                        pathname: `/classes/${event.id}`
                                    });
                                }
                            }}
                            eventPropGetter={(event) => {
                                return { className: `event-${event.type}` }
                            }}
                        />
                    </Col>
                </Row>
            </Fragment>
            }
        </Fragment>
    );
};

export default Schedule;
