import React, { Fragment, useEffect, useState  } from "react";
import firebase from "firebase/app";
import {NavLink as NavigationLink} from "react-router-dom";
import UserAvatar from "react-user-avatar";
import {Breadcrumb, BreadcrumbItem, Button, Col, Container, CustomInput, Form, FormGroup, Label, Row} from "reactstrap";
import {Loading}  from '../../components';
import {roles as allRoles} from "../../roles";

const updateRoles = (userId, roles) => {

    const updatedRoles = [];
    allRoles.forEach(({ value }) => {
        if (roles[value]) {
            updatedRoles.push(value);
        }
    });

    const db = firebase.firestore();
    return db.collection("users").doc(userId).set({
        roles: updatedRoles
    }, { merge: true });

};

const UserDetail = ({ match }) => {

    const userId = match.params.id;

    const [user, setUser] = useState(null);
    const [roles, setRoles ] = useState({});
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const db = firebase.firestore();
        const userRef = db.collection("users").doc(userId);
        userRef.get().then((userDoc) => {
            if (userDoc.exists) {
                const user = userDoc.data();
                if (!user.name) {
                    user.name = user.loginName;
                }
                setUser(user);
                if (user.roles) {
                    const roles = user.roles.reduce((map, role) => {
                        map[role] = true;
                        return map;
                    }, {});
                    setRoles(roles);
                }
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [ userId ]);

    if (!user) {
        return <Loading />
    } else {
        return (
            <Fragment>
                <Breadcrumb tag="nav" listTag="div">
                    <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                    <BreadcrumbItem tag={NavigationLink} to="/users">Users</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">{user.name}</BreadcrumbItem>
                </Breadcrumb>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="text-center">
                                {user.photoURL &&
                                <UserAvatar size="128" name={user.name} src={user.photoURL} className="d-flex justify-content-center my-3" />
                                }
                                <h3>{user.name}</h3>
                                <p>{user.email}</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Fragment>
                                <Form>
                                    <FormGroup>
                                        <Label for="roles">Roles</Label>
                                        <div>
                                            {allRoles.map(({name, value}) => (
                                                <CustomInput
                                                    key={value}
                                                    type="checkbox"
                                                    id={`role-${value}`}
                                                    label={name}
                                                    inline
                                                    checked={roles[value]}
                                                    onChange={({ target }) => {

                                                        const newRoles = {};
                                                        allRoles.forEach(({value}) => {
                                                            newRoles[value] = roles[value];
                                                        });
                                                        newRoles[value] = target.checked;

                                                        setRoles(newRoles);

                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </FormGroup>
                                    <Button color="primary" disabled={updating} onClick={() => {
                                        setUpdating(true);
                                        updateRoles(userId, roles).then(() => {
                                            setUpdating(false);
                                        });
                                    }}>Update</Button>
                                </Form>
                            </Fragment>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }

};

export default UserDetail;
