import React, { Fragment, useContext } from "react";
import {NavLink as NavigationLink} from "react-router-dom";
import UserAvatar from 'react-user-avatar';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import { UserContext } from '../../contexts';

const Profile = () => {

    const user = useContext(UserContext);

    return (
        <div className="text-center">
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Profile</BreadcrumbItem>
            </Breadcrumb>
            {user &&
                <Fragment>
                    {user.photoURL &&
                        <UserAvatar size="128" name={user.name} src={user.photoURL} className="d-flex justify-content-center mt-5 mb-3" />
                    }
                    <h3>{user.name}</h3>
                    <p>{user.email}</p>
                </Fragment>

            }
        </div>
    );
};

export default Profile;
