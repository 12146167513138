import {useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import {UserContext} from "../contexts";

const useClasses = (mineOnly = true) => {

    const [classes, setClasses] = useState(null);

    const user = useContext(UserContext);
    const isClassAdmin = user.hasRole('class-admin');

    useEffect(() => {
        const db = firebase.firestore();
        const list = !mineOnly && isClassAdmin ? db.collection("classes") :
            db.collection("classes").where("accessUserIds","array-contains", user.id);
        list.where("status", "==", 1).get().then((querySnapshot) => {
            const classesDocs = [];
            querySnapshot.forEach((doc) => {
                const classData = doc.data();
                classData.id = doc.id;
                classesDocs.push(classData);
            });
            setClasses(classesDocs);
        });
    }, [mineOnly, isClassAdmin, user.id]);

    return classes;

};

export default useClasses;
