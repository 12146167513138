import React from 'react';
import ReactDOM from 'react-dom';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

import './index.scss';
import 'react-table/react-table.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'leaflet/dist/leaflet.css';
import './App.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

let authDomain = "learn-indonesian-asia.firebaseapp.com";
if (window.location.hostname === 'liana.lia.com.sg') {
    authDomain = 'liana.lia.com.sg'
} else if (window.location.hostname === 'learn-indonesian-asia.web.app') {
    authDomain = 'learn-indonesian-asia.web.app'
}

firebase.initializeApp({
    apiKey: "AIzaSyB8xToRHdERG3g7yMOAEHC1LNwgIQVe1B0",
    authDomain,
    databaseURL: "https://learn-indonesian-asia.firebaseio.com",
    projectId: "learn-indonesian-asia",
    storageBucket: "gs://learn-indonesian-asia.appspot.com/",
    messagingSenderId: "451142476278",
    appId: "1:451142476278:web:782d70008e29b0b4"
});

if (window.location.hostname === "localhost") {
    firebase.functions().useEmulator("localhost", 5001);
    firebase.firestore().useEmulator("localhost", 8080);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
