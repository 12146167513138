import React, {Fragment, useContext, useState, useEffect} from "react";
import {Button, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row} from "reactstrap";
import firebase from "firebase/app";
import {Link} from "react-router-dom";
import moment from "moment";
import {FaChalkboardTeacher, FaCalendarAlt, FaUserAlt, FaMapMarkerAlt, FaClock} from "react-icons/fa";
import {Loading} from '../../components';
import {UserContext} from "../../contexts";
import {useClasses} from "../../hooks";

const Home  = () => {

    const [upcomingLessons, setUpcomingLessons] = useState(null);
    const [notCheckedInLessons, setNotCheckedInLessons] = useState(null);
    const [classIdLessonsMap, setClassIdLessonsMap] = useState({});

    const user = useContext(UserContext);
    const classes = useClasses();

    useEffect(() => {
        let upcomingLessons = null;
        let notCheckedInLessons = null;
        const classIdLessonsMap = {};
        if (classes) {
            upcomingLessons = [];
            notCheckedInLessons = [];
            classes.sort((a, b) => {
                const aTime = a.lessons.length > 0 ? a.lessons[0].startTime.toDate() : Number.MAX_SAFE_INTEGER;
                const bTime = b.lessons.length > 0 ? b.lessons[0].startTime.toDate() : Number.MAX_SAFE_INTEGER;
                return bTime - aTime;
            });
            classes.forEach(({id, type, curriculum, venue, lessons, students}) => {
                classIdLessonsMap[id] = lessons;
                lessons.forEach((lesson, i) => {

                    const teacherId = lesson.teacher.value;
                    const startTime = lesson.startTime.toDate();
                    const endTime = lesson.endTime.toDate();
                    const checkin = lesson.checkin;

                    const rangeStartTime = moment().startOf('day');
                    const rangeEndTime = moment().add(7, 'day').endOf('day');
                    const upcoming = moment(startTime).isBetween(rangeStartTime, rangeEndTime);
                    const past = moment(startTime).isBefore(moment().startOf('day'));

                    const startTimeText = moment(startTime).format('dddd, DD MMM, hh:mm A');
                    const endTimeText = moment(endTime).format('hh:mm A');
                    const title = `${startTimeText} - ${endTimeText}`;
                    const lessonName = `${curriculum.label} - ${type.label} - Lesson ${i + 1}`;
                    const studentNames = students.length > 2 ? students.length : students.join(', ');
                    const noOfMinutes = moment(endTime).diff(moment(startTime), 'minutes');
                    const allowCheckin = moment().endOf('day').isAfter(moment(startTime));

                    if (upcoming && user.id === teacherId) {
                        upcomingLessons.push({
                            classId: id,
                            lessonIndex: i,
                            startTime,
                            title,
                            lessonName,
                            studentNames,
                            venue,
                            noOfMinutes,
                            allowCheckin,
                            checkin
                        });
                    } else if (past && user.id === teacherId && !checkin) {
                        notCheckedInLessons.push({
                            classId: id,
                            lessonIndex: i,
                            startTime,
                            title,
                            lessonName,
                            studentNames,
                            venue,
                            noOfMinutes,
                            allowCheckin,
                            checkin
                        });

                    }
                });
            });
        }

        if (upcomingLessons) {
            upcomingLessons.sort((a, b) => {
                return a.startTime - b.startTime;
            });
        }
        if (notCheckedInLessons) {
            notCheckedInLessons.sort((a, b) => {
                return a.startTime - b.startTime;
            });
        }

        setUpcomingLessons(upcomingLessons);
        setNotCheckedInLessons(notCheckedInLessons);
        setClassIdLessonsMap(classIdLessonsMap);
    }, [classes, user.id]);

    return (
        <Fragment>
            <Row>
                <Col xs={12} md={6}>
                    <h3 className="my-3">Upcoming Lessons {upcomingLessons ? `(${upcomingLessons.length})` : ''}</h3>
                    <ListGroup>
                        {!upcomingLessons &&
                            <Loading />
                        }
                        {upcomingLessons && upcomingLessons.length === 0 &&
                            <ListGroupItem>
                                <ListGroupItemText>
                                    No upcoming lesson is assigned to you.
                                </ListGroupItemText>
                            </ListGroupItem>
                        }
                        {upcomingLessons && upcomingLessons.map(({ classId, lessonIndex, title, lessonName, studentNames, venue, noOfMinutes, allowCheckin, checkin}, i) => {
                            const scheduledHours = `${Math.floor(noOfMinutes / 60)}h ${noOfMinutes % 60 > 0 ? `${noOfMinutes % 60}m`  : ''} `;
                            return (
                                <ListGroupItem key={i}>
                                    <ListGroupItemHeading><FaCalendarAlt className="align-text-top mr-2" />{title}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <span className="d-block">
                                            <FaChalkboardTeacher className="align-text-top mr-2" />{lessonName}
                                        </span>
                                        <span className="d-block">
                                            <FaClock className="align-text-top mr-2" />{scheduledHours}
                                        </span>
                                        <span className="d-block">
                                            <FaMapMarkerAlt className="align-text-top mr-2" />{venue}
                                        </span>
                                        <span className="d-block">
                                            <FaUserAlt className="align-text-top mr-2" />Student(s): {studentNames}
                                        </span>
                                        <span className="d-block my-2">
                                            {checkin &&
                                            <Fragment>
                                                <span className="text-muted">Checked in by {checkin.user.name} on {moment(checkin.time.toDate ? checkin.time.toDate() : checkin.time).format('dddd, DD MMM YYYY hh:mm A')}</span>
                                            </Fragment>
                                            }
                                            {!checkin && allowCheckin &&
                                            <Button color="primary" onClick={() => {

                                                const checkinData = {
                                                    user: {
                                                        id: user.id,
                                                        name: user.name
                                                    },
                                                    time: new Date()
                                                };

                                                const newUpcomingLessons = [...upcomingLessons];
                                                newUpcomingLessons[i].checkin = checkinData;
                                                setUpcomingLessons(newUpcomingLessons);

                                                const newLessons = [...classIdLessonsMap[classId]];
                                                newLessons[lessonIndex].checkin = checkinData;
                                                const db = firebase.firestore();
                                                db.collection("classes").doc(classId).set({
                                                    lessons: newLessons
                                                }, {merge: true}).catch((error) => {
                                                    console.error("Error setting lessons: ", error);
                                                });

                                            }}>
                                                Check In
                                            </Button>
                                            }
                                        </span>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                    <h3 className="my-3">Not Checked In Lessons {notCheckedInLessons ? `(${notCheckedInLessons.length})` : ''}</h3>
                    <ListGroup>
                        {!notCheckedInLessons &&
                        <Loading />
                        }
                        {notCheckedInLessons && notCheckedInLessons.length === 0 &&
                        <ListGroupItem>
                            <ListGroupItemText>
                                No past lessons to check in.
                            </ListGroupItemText>
                        </ListGroupItem>
                        }
                        {notCheckedInLessons && notCheckedInLessons.map(({ classId, lessonIndex, title, lessonName, studentNames, venue, noOfMinutes, allowCheckin, checkin}, i) => {
                            const scheduledHours = `${Math.floor(noOfMinutes / 60)}h ${noOfMinutes % 60 > 0 ? `${noOfMinutes % 60}m`  : ''} `;
                            return (
                                <ListGroupItem key={i}>
                                    <ListGroupItemHeading><FaCalendarAlt className="align-text-top mr-2" />{title}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <span className="d-block">
                                            <FaChalkboardTeacher className="align-text-top mr-2" />{lessonName}
                                        </span>
                                        <span className="d-block">
                                            <FaClock className="align-text-top mr-2" />{scheduledHours}
                                        </span>
                                        <span className="d-block">
                                            <FaMapMarkerAlt className="align-text-top mr-2" />{venue}
                                        </span>
                                        <span className="d-block">
                                            <FaUserAlt className="align-text-top mr-2" />Student(s): {studentNames}
                                        </span>
                                        <span className="d-block my-2">
                                            {checkin &&
                                            <Fragment>
                                                <span className="text-muted">Checked in by {checkin.user.name} on {moment(checkin.time.toDate ? checkin.time.toDate() : checkin.time).format('dddd, DD MMM YYYY hh:mm A')}</span>
                                            </Fragment>
                                            }
                                            {!checkin && allowCheckin &&
                                            <Button color="primary" onClick={() => {

                                                const checkinData = {
                                                    user: {
                                                        id: user.id,
                                                        name: user.name
                                                    },
                                                    time: new Date()
                                                };

                                                const newNotCheckedInLessons = [...notCheckedInLessons];
                                                newNotCheckedInLessons[i].checkin = checkinData;
                                                setNotCheckedInLessons(newNotCheckedInLessons);

                                                const newLessons = [...classIdLessonsMap[classId]];
                                                newLessons[lessonIndex].checkin = checkinData;
                                                const db = firebase.firestore();
                                                db.collection("classes").doc(classId).set({
                                                    lessons: newLessons
                                                }, {merge: true}).catch((error) => {
                                                    console.error("Error setting lessons: ", error);
                                                });

                                            }}>
                                                Check In
                                            </Button>
                                            }
                                        </span>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Col>
                <Col>
                    <h3 className="my-3">My Classes {classes ? `(${classes.length})` : ''}</h3>
                    <ListGroup>
                        {!classes &&
                            <Loading />
                        }
                        {classes && classes.length === 0 &&
                            <ListGroupItem>
                                <ListGroupItemText>
                                    No class is assigned to you.
                                </ListGroupItemText>
                            </ListGroupItem>
                        }
                        {classes && classes.map(({ id, curriculum, type, venue, firstLessonStartTime, lastLessonStartTime, students}, i) => {
                            const regularTime = lastLessonStartTime ? lastLessonStartTime : firstLessonStartTime;
                            const time = regularTime ? moment(regularTime.toDate()).format("dddd hh:mm A") : 'not scheduled';
                            const title = `${curriculum.label} - ${type.label} ${venue ? `(${venue})` : ''} - ${time}`;
                            const studentNames = students.length > 2 ? students.length : students.join(', ');
                            return (
                                <ListGroupItem key={i}>
                                    <ListGroupItemHeading><Link to={`/classes/${id}`}><FaChalkboardTeacher className="align-text-top mr-2"/>{title}</Link></ListGroupItemHeading>
                                    <ListGroupItemText>
                                        <FaUserAlt className="align-text-top mr-2" />Student(s): {studentNames}
                                    </ListGroupItemText>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Home;
