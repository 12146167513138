import React, { useContext, Fragment } from "react";
import {FaPlus} from "react-icons/fa";
import {NavLink as NavigationLink} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button, Col, NavLink, Row} from "reactstrap";
import moment from "moment";
import numeral from "numeral";
import ReactTable from "react-table";
import {useTimesheets, useUsers} from "../../hooks";
import {Loading} from "../../components";
import {statusText} from "../../constants/TimesheetConstants";
import {UserContext} from "../../contexts";

const TimesheetsTable = ({ timesheets }) => {

    const user = useContext(UserContext);
    const users = useUsers();

    const data = timesheets.sort((a, b) => {
        return b.submittedDate.toDate() - a.submittedDate.toDate();
    }).map(({ id, lessons, otherFeeItems, ownerId, submittedBy, submittedDate, status, statusDate }) => {

        let total = 0;
        if (lessons) {
            lessons.forEach(({ noOfHours, hourlyRate}) => {
                total += noOfHours * hourlyRate;
            });
        }
        if (otherFeeItems) {
            otherFeeItems.forEach(({value}) => {
                total += value;
            });
        }

        return {
            id,
            total,
            owner: users[ownerId],
            submittedBy: users[submittedBy],
            submittedDate: submittedDate.toDate(),
            status: statusText[status],
            statusDate: statusDate.toDate()
        }
    });

    data.sort((a, b) => {
        return b.submittedDate - a.submittedDate;
    });

    const columns = [{
        Header: 'Timesheet',
        accessor: 'id',
        minWidth: 150,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const submittedDate = moment(cellProps.original.submittedDate).format('D MMM YYYY');
            const owner = cellProps.original.owner;
            const value = cellProps.value;
            return (
                <div className="text-center">
                    <NavLink
                        tag={NavigationLink}
                        to={`/timesheets/${value}`}
                    >
                        {submittedDate}
                    </NavLink>
                    {owner && owner.id !== user.id &&
                    <span className="text-muted">
                        {owner.name}
                    </span>
                    }
                </div>
            )
        }
    },{
        Header: 'Amount',
        accessor: 'total',
        minWidth: 150,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const value = cellProps.value;
            return (
                <div className="m-2 text-center">
                    {numeral(value).format('$0,0')}
                </div>
            )
        }
    },{
        Header: 'Status',
        accessor: 'status',
        minWidth: 150,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const value = cellProps.value;
            return (
                <div className="m-2 text-center">
                    {value}
                </div>
            )
        }
    },{
        Header: 'Updated',
        accessor: 'statusDate',
        minWidth: 150,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const value = cellProps.value;
            return (
                <div className="m-2 text-center">
                    {moment(value).format('D MMM YYYY')}
                </div>
            )
        }
    }];

    return <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={10}
        sortable={false}
    />

};

const Timesheets = ({ history }) => {

    const timesheets = useTimesheets(false);

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Timesheets</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col className="text-right">
                    <Button color="primary" className="m-3" onClick={() => {
                        history.push({
                            pathname: '/timesheets/new'
                        });
                    }}><FaPlus className="align-text-top mr-2" size={18} />Add new timesheet</Button>
                </Col>
            </Row>
            {!timesheets && <Loading />}
            {timesheets && <TimesheetsTable timesheets={timesheets} />}
        </Fragment>
    );
};

export default Timesheets;
