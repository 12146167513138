import React, {Fragment} from 'react';

import {NavLink as NavigationLink, Route, Switch} from "react-router-dom";
import {NavItem, NavLink} from "reactstrap";
import {Home, Schedule, Classes, ClassDetail, Timesheets, TimesheetDetail, Students, StudentDetail, Users, UserDetail, Profile, PageNotFound} from "./pages";

import ProfileMenu from "./components/header/ProfileMenu"

// Page Configuration
const pages = [{
    path: '/',
    menu: 'Home',
    component: Home
},{
    path: '/schedule',
    menu: 'Schedule',
    component: Schedule,
    roles: ['teacher', 'class-admin']
},{
    path: '/classes',
    menu: 'Classes',
    component: Classes,
    roles: ['teacher', 'class-admin']
},{
    path: '/classes/:id',
    component: ClassDetail,
    roles: ['teacher', 'class-admin']
},{
    path: '/timesheets',
    menu: 'Timesheets',
    component: Timesheets,
    roles: ['teacher', 'user-admin']
},{
    path: '/timesheets/:id',
    component: TimesheetDetail,
    roles: ['teacher', 'user-admin']
},{
    path: '/students',
    menu: 'Students',
    component: Students,
    roles: ['user-admin']
},{
    path: '/students/:id',
    component: StudentDetail,
    roles: ['user-admin']
},{
    path: '/users',
    menu: 'Users',
    component: Users,
    roles: ['user-admin']
},{
    path: '/users/:id',
    component: UserDetail,
    roles: ['user-admin']
},{
    path: '/profile',
    component: Profile
},];

const NavMenuItem = ({path, label}) => {
    return (
        <NavItem>
            <NavLink
                tag={NavigationLink}
                exact
                to={path}
                activeClassName="active"
            >
                {label}
            </NavLink>
        </NavItem>
    );
};

const roleFilter = (userRoles = []) => {
    return ({ roles }) => {
        if (roles) {
            return roles.some(v => userRoles.indexOf(v) !== -1)
        } else {
            return true;
        }
    }
};

const MenuItems = ({ user, onLogout }) => {
    const menus = pages.filter(({ menu }) => menu).filter(roleFilter(user.roles));
    return (
        <Fragment>
            {menus.map(({path, menu}) => (
                <NavMenuItem key={path} path={path} label={menu} />
            ))}
            <ProfileMenu user={user} onLogout={onLogout} />
        </Fragment>
    );
};

const Page = ({ user }) => {
    const routes = pages.filter(roleFilter(user.roles));
    return (
        <Switch>
            {routes.map(({ path, component }) => (
                <Route key={path} path={path} exact component={component} />
            ))}
            <Route component={PageNotFound} />
        </Switch>
    );
};

export { Page, MenuItems};