import React, { Fragment, useEffect, useState } from "react";
import firebase from "firebase/app"
import {NavLink as NavigationLink} from "react-router-dom";
import {FaPlus, FaListUl, FaMapMarkerAlt} from "react-icons/fa";
import ReactTable from "react-table";
import {NavLink, Breadcrumb, BreadcrumbItem, Row, Col, Button, ButtonGroup} from "reactstrap";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import {Loading} from '../../components';

import MarkerIcon from 'leaflet/dist/images/marker-icon.png';
import MarkerShadowIcon from 'leaflet/dist/images/marker-shadow.png';

const StudentsMap = ({ students }) => {

    const position = [1.3521, 103.8198];
    const zoom = 11;

    const icon = L.icon({
        iconUrl: MarkerIcon,
        shadowUrl: MarkerShadowIcon,
        iconAnchor: L.point(20,41)
    });

    return (
        <Map center={position} zoom={zoom}>
            <TileLayer  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {students && students.map((student) => (
                <Marker key={student.id} icon={icon} position={[student.location.latitude, student.location.longitude]}>
                    <Popup>
                        {student.location.address}
                    </Popup>
                </Marker>
            ))}
        </Map>
    )
}

const StudentsTable = ({ students }) => {

    students.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    const data = students;

    const columns = [{
        Header: 'Name',
        accessor: 'name',
        minWidth: 300,
        Cell: cellProps => {
            const name = cellProps.value;
            const id = cellProps.original.id;
            return (
                <div className="d-flex align-items-center h-100 p-2">
                    <div title={name}>
                        <NavLink
                            tag={NavigationLink}
                            to={`/students/${id}`}
                        >
                        {name}
                        </NavLink>
                    </div>
                </div>
            )
        }
    }, {
        Header: 'E-mail',
        accessor: 'email',
        minWidth: 300,
        Cell: cellProps => {
            const email = cellProps.value;
            return (
                <div className="d-flex align-items-center h-100 p-2">
                    <div title={email}>
                        {email}
                    </div>
                </div>
            )
        }
    }, {
        Header: 'Address',
        accessor: 'address',
        minWidth: 300,
        Cell: cellProps => {
            const address = cellProps.value;
            return (
                <div className="d-flex align-items-center h-100 p-2">
                    <div title={address}>
                        {address}
                    </div>
                </div>
            )
        }
    }];

    const filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
    return <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={10}
        sortable={false}
        filterable={true}
        defaultFilterMethod={filterCaseInsensitive}
    />

};

const Students = ({ history }) => {

    const [students, setStudents] = useState(null);
    const [view, setView] = useState('list');

    useEffect(() => {
        const db = firebase.firestore();
        db.collection("students").get().then((querySnapshot) => {
            const studentDocs = [];
            querySnapshot.forEach((doc) => {
                const studentData = doc.data();
                studentData.id = doc.id;
                studentDocs.push(studentData);
            });
            setStudents(studentDocs);
        });

    }, []);

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Students</BreadcrumbItem>
            </Breadcrumb>
            <Row>
                <Col>
                    <div className="d-flex">
                        <div>
                            <ButtonGroup className="m-3">
                                <Button color={`${view === 'list' ? 'primary' : 'secondary'}`}
                                  onClick={() => {
                                      setView('list')
                                  }}
                                ><FaListUl /></Button>
                                <Button color={`${view === 'map' ? 'primary' : 'secondary'}`}
                                        onClick={() => {
                                            setView('map')
                                        }}
                                ><FaMapMarkerAlt /></Button>
                            </ButtonGroup>
                        </div>
                        <div className="ml-auto">
                            <Button color="primary" className="m-3" onClick={() => {
                                history.push({
                                    pathname: '/students/new'
                                });
                            }}><FaPlus className="align-text-top mr-2" size={18} />Add new student</Button>
                        </div>
                    </div>
                </Col>
            </Row>
            {!students && <Loading />}
            {students && view === 'list' && <StudentsTable students={students} />}
            {students && view === 'map' && <StudentsMap students={students} />}
        </Fragment>
    );
};

export default Students;
