import React, {Fragment, useContext, useState} from "react";
import {NavLink as NavigationLink} from "react-router-dom";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler} from "reactstrap";
import {UserContext} from "../../contexts";
import {MenuItems} from "../../routes";
import Icon from "./icon.png";

const Header = () => {

    const [isOpen, setOpen] = useState(false);
    let user = useContext(UserContext);

    return (
        <div>
            <Navbar light expand="md">
                <NavbarBrand
                    tag={NavigationLink}
                    exact to="/"
                    className="text-primary mr-auto"
                >
                    <img src={Icon} alt="Liana" className="align-text-bottom mr-1" style={{ width: '24px' }}/>
                    Liana
                </NavbarBrand>
                {user &&
                    <Fragment>
                        <NavbarToggler onClick={() => setOpen(!isOpen)} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <MenuItems user={user} onLogout={() => { user = null; }} />
                            </Nav>
                        </Collapse>
                    </Fragment>
                }
            </Navbar>
        </div>
    );

};

export default Header;
