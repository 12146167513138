import {useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import {UserContext} from "../contexts";

const useTimesheets = (mineOnly = true) => {

    const [timesheets, setTimesheets] = useState(null);

    const user = useContext(UserContext);

    useEffect(() => {
        if (user) {
            const isUserAdmin = user.hasRole('user-admin');
            const db = firebase.firestore();
            const list = !mineOnly && isUserAdmin ? db.collection("timesheets") :
                db.collection("timesheets").where("ownerId","==", user.id);
            list.get().then((querySnapshot) => {
                const timesheetsDocs = [];
                querySnapshot.forEach((doc) => {
                    const timesheetData = doc.data();
                    timesheetData.id = doc.id;
                    timesheetsDocs.push(timesheetData);
                });
                setTimesheets(timesheetsDocs);
            });
        } else {
            setTimesheets(null);
        }
    }, [mineOnly, user]);

    return timesheets;

};

export default useTimesheets;
