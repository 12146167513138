import {useEffect, useState} from "react";
import firebase from "firebase/app";

const useUsers = () => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection("users").get().then((querySnapshot) => {
            const userDocs = [];
            querySnapshot.forEach((doc) =>{
                userDocs.push(doc.data());
            });
            setUsers(userDocs);
        });
    }, []);

    const map = [];
    users.forEach((user) => {
        map[user.id] = user;
    });
    return map;
};

export default useUsers;