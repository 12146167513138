import React, { Fragment, useEffect, useState } from "react";
import firebase from "firebase/app"
import moment from 'moment';
import {NavLink as NavigationLink} from "react-router-dom";
import {FaCheckSquare} from "react-icons/fa";
import ReactTable from "react-table";
import UserAvatar from "react-user-avatar";
import {NavLink, Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Loading} from '../../components';
import {roles as allRoles, roleMap} from "../../roles";

const roleCellRenderer = (role) => {
    return cellProps => {
        const hasRole =  cellProps.value[role];
        const roleName = roleMap[role];
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 p-2">
                {hasRole && <span title={roleName}><FaCheckSquare size={20} className="text-success" /></span>}
            </div>
        )
    }
};

const UsersTable = ({ users }) => {

    const data = users.map((user) => {

        const roles = user.roles || [];
        const rolesMap = roles.reduce((map, role) => {
            map[role] = true;
            return map;
        }, {});
        return {
            ...user,
            name: user.name || user.loginName,
            rolesMap
        };
    }).sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const roleColumns = allRoles.map(({ shortName, name, value }) => {
        return {
            Header: shortName || name,
            accessor: 'rolesMap',
            width: 50,
            Cell: roleCellRenderer(value)
        }
    });

    const columns = [{
        Header: 'User',
        columns: [{
            Header: 'Name',
            accessor: 'name',
            minWidth: 300,
            Cell: cellProps => {
                const name = cellProps.value;
                const id = cellProps.original.id;
                const photoURL = cellProps.original.photoURL;
                return (
                    <div className="d-flex align-items-center h-100 p-2">
                        <UserAvatar size="48" name={name} src={photoURL} />
                        <div title={name}>
                            <NavLink
                                tag={NavigationLink}
                                to={`/users/${id}`}
                            >
                            {name}
                            </NavLink>
                        </div>
                    </div>
                )
            }
        }, {
            Header: 'E-mail',
            accessor: 'email',
            minWidth: 300,
            Cell: cellProps => {
                const email = cellProps.value;
                return (
                    <div className="d-flex align-items-center h-100 p-2">
                        <div title={email}>
                            {email}
                        </div>
                    </div>
                )
            }
        }, {
            Header: 'Last Login',
            accessor: 'lastLogin',
            minWidth: 200,
            Cell: cellProps => {
                const lastLogin = cellProps.value;
                if (lastLogin) {
                    const lastLoginText = moment(lastLogin.toDate()).fromNow();
                    return (
                        <div className="d-flex align-items-center h-100 p-2 justify-content-center">
                            <div title={lastLoginText}>
                                {lastLoginText}
                            </div>
                        </div>
                    )
                } else {
                    return null;
                }
            }
        }]
    },{
        Header: 'Roles',
        columns: roleColumns
    }];

    return <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={10}
        sortable={false}
    />

};

const Users = () => {

    const [users, setUsers] = useState(null);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection("users").get().then((querySnapshot) => {
            const userDocs = [];
            querySnapshot.forEach((doc) => {
                userDocs.push(doc.data());
            });
            setUsers(userDocs);
        });

    }, []);

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Users</BreadcrumbItem>
            </Breadcrumb>
            {!users && <Loading />}
            {users && <UsersTable users={users} />}
        </Fragment>
    );
};

export default Users;
