

const roles = [{
    name: 'System Admin',
    shortName: 'SA',
    value: 'system-admin'
},{
    name: 'User Admin',
    shortName: 'UA',
    value: 'user-admin'
},{
    name: 'Class Admin',
    shortName: 'CA',
    value: 'class-admin'
},{
    name: 'Teacher',
    shortName: 'T',
    value: 'teacher'
}];

const roleMap = roles.reduce((map, { name, value }) => {
    map[value] = name;
    return map;
}, {});

export { roles, roleMap };
