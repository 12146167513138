import React, {Fragment, useEffect, useState} from "react";
import firebase from "firebase/app";
import {NavLink as NavigationLink} from "react-router-dom";
import {Alert, Breadcrumb, BreadcrumbItem, Button, Col, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import { FaBuilding } from "react-icons/fa";
import { useDebounce } from '../../hooks';

function getMapUrl(lat, lon, width, height) {
    return 'https://developers.onemap.sg/commonapi/staticmap/getStaticImage' +
        '?layerchosen=original&zoom=17' +
        '&height=' + height + '&width=' + width +
        '&lat=' + lat + '&lng=' + lon +
        '&points=['+lat+','+lon+']';
}

const ClassDetail = ({ match, history }) => {

    const [insertMode, setInsertMode] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [alert, setAlert] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [location, setLocation] = useState(null);
    const [searchLocation, setSearchLocation] = useState("");
    const [locations, setLocations] = useState(null);

    const studentId = match.params.id;
    useEffect(() => {

        const insertMode = (studentId === 'new');
        setInsertMode(insertMode);

        if (!insertMode) {
            const db = firebase.firestore();
            const studentRef = db.collection("students").doc(studentId);
            studentRef.get().then((studentDoc) => {
                if (studentDoc.exists) {
                    const studentData = studentDoc.data();
                    setName(studentData.name);
                    setEmail(studentData.email);
                    setAddress(studentData.address);
                    setLocation(studentData.location);
                    setSearchLocation(studentData.searchLocation);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

    }, [studentId]);

    const queryLocation = useDebounce(searchLocation => {
        if (!searchLocation || searchLocation.length < 3) {
            return;
        }
        fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${searchLocation}&returnGeom=Y&getAddrDetails=Y&pageNum=1`)
            .then(response => response.json())
            .then(data => {
               const  { results } = data;
               const locations = results.map((result) => {
                  return {
                      building: result.BUILDING,
                      address: result.ADDRESS,
                      latitude: result.LATITUDE,
                      longitude: result.LONGITUDE
                  }
               });
               setLocations(locations);
            });

    }, 500);

    useEffect(() => {
        queryLocation(searchLocation);
    }, [searchLocation, queryLocation]);

    useEffect(() => {

        if (alert) {
            const timer = setTimeout(() => {
                setAlert(null);
            }, 2000);
            return () => {
                clearTimeout(timer);
            }
        }

    }, [ alert ]);

    const save = () => {
        setUpdating(true);

        const studentDetails = {
          name,
          email,
          address,
          location,
          searchLocation
        };

        const db = firebase.firestore();
        if (insertMode) {
            studentDetails.created = new Date();
            db.collection("students").add(studentDetails)
                .then((docRef) => {
                    setUpdating(false);
                    console.log("Document written with ID: ", docRef.id);
                    history.push({
                        pathname: `/students`
                    });
                })
                .catch((error) => {
                    setUpdating(false);
                    setAlert({type: "alert", message: "Error creating student"});
                    console.error("Error adding document: ", error);
                });
        } else {
            studentDetails.updated = new Date();
            db.collection("students").doc(studentId).set(studentDetails, {merge: true})
                .then(() => {
                    setUpdating(false);
                    setAlert({type: "success", message: "Student is updated."});
                })
                .catch((error) => {
                    setUpdating(false);
                    setAlert({type: "alert", message: "Error updating student"});
                    console.error("Error updating document: ", error);
                });
        }

    };

    const isValid = name && address && location;

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem tag={NavigationLink} to="/students">Students</BreadcrumbItem>
                { insertMode && <BreadcrumbItem active tag="span">Create new student</BreadcrumbItem> }
                { !insertMode && <BreadcrumbItem active tag="span">{name}</BreadcrumbItem> }
            </Breadcrumb>
            {alert &&
            <Row>
                <Col>
                    <Alert color={alert.type}>{alert.message}</Alert>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <h3>Details</h3>
                </Col>
            </Row>
            <Form>
                <Row className="mb-2">
                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input type="text" name="venue" id="name" value={name} onChange={(ev) => setName(ev.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" value={email} onChange={(ev) => setEmail(ev.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="address">Address</Label>
                            <Input type="text" name="address" id="address" value={address} onChange={(ev) => setAddress(ev.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="searchLocation">Search for location</Label>
                            <Input placeholder="Type to search for location" type="text" name="searchLocation" id="searchLocation" value={searchLocation} onChange={(ev) => setSearchLocation(ev.target.value)}/>
                        </FormGroup>
                        {location &&
                        <div>
                            <img alt={location.building} className="img-thumbnail" src={getMapUrl(location.latitude, location.longitude, 512, 384)} />
                            <p className="my-2">{location.building} - {location.address}</p>
                        </div>
                        }
                        {locations &&
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Building</th>
                                    <th>Address</th>
                                </tr>
                                </thead>
                                <tbody>
                                {locations.length === 0 &&
                                    <tr>
                                        <td colSpan="3">There is no location match.</td>
                                    </tr>
                                }
                                {locations.length > 0 && locations.map(({ building, address, longitude, latitude }) => {

                                    const selectedLocation = location && location.building === building &&
                                        location.longitude === longitude && location.latitude === latitude;
                                    return (
                                        <tr className={`${selectedLocation ? 'table-success' : ''}`}>
                                            <th scope="row">
                                                <Button color="primary" size="sm" onClick={() => {
                                                    setLocation({ building, address, longitude, latitude });
                                                }}><FaBuilding /></Button>
                                            </th>
                                            <td>{building}</td>
                                            <td>{address}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Button color="primary" disabled={!isValid || updating} className="my-3 ml-2" onClick={save}>
                            {updating &&
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            }
                            {insertMode && <span>Submit</span>}
                            {!insertMode && <span>Save changes</span>}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );

};

export default ClassDetail;
