import React, {Fragment, useContext, useEffect, useState} from "react";
import firebase from 'firebase/app';
import {FaTrashAlt} from "react-icons/fa"
import Select from "react-select";
import {Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row, Table} from "reactstrap";
import moment from "moment";
import QRCode from "react-qr-code";
import {UserContext} from "../../contexts";

const ClassSchedule = ({ classDetails, classId, courseRun, courseRunSessions, allowUpdate, onUpdate }) => {

    const user = useContext(UserContext);

    const { totalHours, carryForwardHours } = classDetails;

    const [updating, setUpdating] = useState(false);
    const [lessons, setLessons] = useState(classDetails.lessons);
    const [selectedAttendanceSessionId, setSelectedAttendanceSessionId] = useState(null);
    const [selectedAttendanceTrainees, setSelectedAttendanceTrainees] = useState(null);

    useEffect(() => {
        if (selectedAttendanceSessionId) {
            const { id: runId, organizationKey: uen, referenceNumber: courseRefNo } = courseRun;
            const getSSGCourseRunSessionAttendance = firebase.functions().httpsCallable('getSSGCourseRunSessionAttendance');
            getSSGCourseRunSessionAttendance({ runId: String(runId), sessionId: selectedAttendanceSessionId, uen, courseRefNo }).then(({ data }) => {
                if (data.status === 'ok') {
                    const sessionAttendees = data.courseRunSessionAttendance.data;
                    const sessionTrainees = sessionAttendees.map((attendees) => attendees.trainee.name);
                    sessionTrainees.sort();
                    setSelectedAttendanceTrainees(sessionTrainees)
                }
                // TODO: Show the attendance
                /*
                {
    "status": "ok",
    "code": 200,
    "courseRunSessionAttendance": {
        "data": [
            {
                "id": "21989074",
                "status": "Confirmed",
                "trainee": {
                    "name": "KELVIN KWOK YING CHOY"
                }
            },
            {
                "id": "21989077",
                "status": "Confirmed",
                "trainee": {
                    "name": "CHENG SZE YUEN"
                }
            },
            {
                "id": "21989071",
                "status": "Confirmed",
                "trainee": {
                    "name": "DINESH SURESH"
                }
            }
        ]
    }
}

                 */
            })
        } else {
            setSelectedAttendanceTrainees(null)
        }
    }, [selectedAttendanceSessionId, setSelectedAttendanceTrainees, courseRun]);

    useEffect(() => {
        let lessons = classDetails.lessons.sort((a, b) => {
            return a.startTime - b.startTime;
        });
        if (courseRunSessions) {
            lessons = lessons.map((lesson) => {
                const mappedLesson = {...lesson};
                const lessonStartDate = moment(mappedLesson.startTime).format('YYYYMMDD');
                const lessonStartTime = moment(mappedLesson.startTime).format('HH:mm');
                const lessonEndDate = moment(mappedLesson.endTime).format('YYYYMMDD');
                const lessonEndTime = moment(mappedLesson.endTime).format('HH:mm');
                mappedLesson.courseRunSession = courseRunSessions.find(({ startDate, startTime, endDate, endTime, deleted}) => {
                    return !deleted
                        && startDate === lessonStartDate
                        && startTime === lessonStartTime
                        && endDate === lessonEndDate
                        && endTime === lessonEndTime
                });
                return mappedLesson;
            });
        }
        setLessons(lessons);
    }, [classDetails.lessons, courseRunSessions]);

    const progress = lessons.reduce((map, { startTime, endTime, checkin }) => {

        const start = moment(startTime);
        const end = moment(endTime);
        const scheduled = end.diff(start, 'minutes');

        map.scheduledMinutes += scheduled;
        if (checkin) {
            map.completedMinutes += scheduled;
        }

        return map;
    }, {
        scheduledMinutes: 0,
        completedMinutes: 0
    });

    const expectedScheduledMinutes = (totalHours - carryForwardHours) * 60;
    const scheduledMinutes = progress.scheduledMinutes;

    let color = 'success';
    if (scheduledMinutes < expectedScheduledMinutes) {
        color = 'warning';
    } else if (scheduledMinutes > expectedScheduledMinutes) {
        color = 'danger';
    }

    const scheduledHours = `${Math.floor(scheduledMinutes / 60)}h ${scheduledMinutes % 60 > 0 ? `${scheduledMinutes % 60}m`  : ''} `;
    const expectedScheduledHours = `${Math.floor(expectedScheduledMinutes / 60)}h ${expectedScheduledMinutes % 60 > 0 ? `${expectedScheduledMinutes % 60}m`  : ''} `;

    const saveChanges = () => {
        setUpdating(true);

        const sortedLessons = lessons.sort((a, b) => {
            return a.startTime - b.startTime;
        });

        const fullyScheduled = (scheduledMinutes >= expectedScheduledMinutes);

        const db = firebase.firestore();
        classDetails.lessons = sortedLessons;
        classDetails.firstLessonStartTime = sortedLessons.length > 0 ? sortedLessons[0].startTime : null;
        classDetails.firstLessonEndTime = sortedLessons.length > 0 ? sortedLessons[0].endTime : null;
        classDetails.lastLessonStartTime = sortedLessons.length > 0 && fullyScheduled ? sortedLessons[sortedLessons.length - 1].startTime : null;
        classDetails.lastLessonEndTime = sortedLessons.length > 0 && fullyScheduled ? sortedLessons[sortedLessons.length - 1].endTime : null;

        db.collection("classes").doc(classId).set(classDetails, {merge: true})
            .then(() => {
                setUpdating(false);
                onUpdate(lessons);
            })
            .catch((error) => {
                setUpdating(false);
                console.error("Error updating class: ", error);
            });

    };

    const toggleSessionAttendance = () => {
        setSelectedAttendanceSessionId(null);
    };

    return (
        <Fragment>
            <Row>
                <Col>
                    <h3>
                        Schedule
                    </h3>
                    <p className="text-muted">Tips: Changes will only be applied after <strong>Save Changes</strong> button is clicked.</p>
                </Col>
            </Row>
            <Row className="my-2">
                <Col md={{size: 6, offset: 6}} lg={{size: 4, offset: 8}}>
                    <div>
                        <div className="text-center">{scheduledHours} of {expectedScheduledHours}</div>
                        <Progress color={color} value={scheduledMinutes} max={expectedScheduledMinutes} />
                    </div>
                </Col>
            </Row>
            {allowUpdate &&
            <Row className="my-3">
                <Col>
                    <Button color="primary" disabled={updating} className="my-3 ml-2" onClick={saveChanges}>
                        {updating &&
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        <span>Save changes</span>
                    </Button>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <Table striped responsive>
                        <thead>
                        <tr>
                            <th style={{ width: '50px' }} className="text-center">#</th>
                            <th>Lesson</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lessons && lessons.length === 0 &&
                        <tr>
                            <th>
                            </th>
                            <td>
                                No lesson is created yet.
                            </td>
                        </tr>
                        }
                        {lessons && lessons.map(({ startTime, endTime, teacher, checkin, courseRunSession }, i) => {
                            const diffMins = moment(endTime).diff(moment(startTime), 'minutes');
                            const startDateStr = moment(startTime).format('dddd, DD MMMM YYYY');
                            const startTimeStr = moment(startTime).format('hh:mm A');
                            const endTimeStr = moment(endTime).format('hh:mm A');
                            const allowCheckin = moment().endOf('day').isAfter(moment(startTime).add(-30, 'minutes'));
                            return (
                                <tr key={`lesson-${i}`}>
                                    <th className="text-center" scope="row">
                                        <div>{i + 1}</div>
                                        {allowUpdate &&
                                            <div className="mt-2">
                                            <Button color="danger" size="sm" disabled={updating} onClick={() => {
                                                let newLessons = [...lessons];
                                                newLessons.splice(i, 1);
                                                setLessons(newLessons);
                                            }}><FaTrashAlt/></Button>
                                            </div>
                                        }
                                    </th>
                                    <td>
                                        <div className="w-100 d-flex flex-column flex-md-row">
                                            {allowUpdate &&
                                            <div>
                                                <Input type="date" step="any"
                                                       value={moment(startTime).format('YYYY-MM-DD')}
                                                       onChange={(ev) => {
                                                           const value = ev.target.value;
                                                           const newLessons = [...lessons];
                                                           const startTime = moment(newLessons[i].startTime).format('HH:mm');
                                                           const endTime = moment(newLessons[i].endTime).format('HH:mm');
                                                           newLessons[i].startTime = moment(`${value} ${startTime}`).toDate();
                                                           newLessons[i].endTime = moment(`${value} ${endTime}`).toDate();
                                                           setLessons(newLessons);
                                                       }}
                                                />
                                                <Input type="time" step="any"
                                                       value={moment(startTime).format('HH:mm')}
                                                       onChange={(ev) => {
                                                           const value = ev.target.value;
                                                           const newLessons = [...lessons];
                                                           const startDate = moment(newLessons[i].startTime).format('YYYY-MM-DD');
                                                           const diffMins = moment(newLessons[i].endTime).diff(moment(newLessons[i].startTime), 'minutes');
                                                           newLessons[i].startTime = moment(`${startDate} ${value}`).toDate();
                                                           newLessons[i].endTime = moment(newLessons[i].startTime).add(diffMins, 'minutes').toDate();
                                                           setLessons(newLessons);
                                                       }}
                                                />
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Input type="number" step="any" min="0"
                                                               value={diffMins}
                                                               onChange={(ev) => {
                                                                   const value = ev.target.value;
                                                                   const numberValue = Number(value);
                                                                   if (!isNaN(numberValue)) {
                                                                       const newLessons = [...lessons];
                                                                       newLessons[i].endTime = moment(newLessons[i].startTime).add(numberValue, 'minutes').toDate();
                                                                       setLessons(newLessons);
                                                                   }
                                                               }}
                                                        />
                                                    </div>
                                                    <div className="pl-2">minutes</div>
                                                </div>
                                            </div>
                                            }
                                            <div className="d-flex flex-column flex-sm-row flex-md-column text-nowrap py-2 py-md-0 px-0 px-md-3">
                                                <div>{startDateStr}</div>
                                                <div className="pl-1 pl-md-0">{startTimeStr} - {endTimeStr}</div>
                                            </div>
                                            <div className="w-100 d-flex flex-column">
                                                <div>
                                                    <Select isDisabled={!allowUpdate || checkin} options={classDetails.teachers} value={teacher} onChange={(teacher) => {
                                                        const newLessons= [...lessons];
                                                        newLessons[i].teacher = teacher;
                                                        setLessons(newLessons);
                                                    }}
                                                    />
                                                </div>
                                                <div className="pt-3">
                                                    {checkin &&
                                                    <Fragment>
                                                        {/* TODO: Class admin can remove check in, unless the timesheet is already submitted */}
                                                        <span className="text-muted">Checked in by {checkin.user.name} on {moment(checkin.time.toDate ? checkin.time.toDate() : checkin.time).format('dddd, DD MMM YYYY hh:mm A')}</span>
                                                    </Fragment>
                                                    }
                                                    {!checkin && allowCheckin &&
                                                    <Button disabled={updating} color="primary" onClick={() => {
                                                        const newLessons= [...lessons];
                                                        newLessons[i].checkin = {
                                                            user: {
                                                                id: user.id,
                                                                name: user.name
                                                            },
                                                            time: new Date()
                                                        };
                                                        setLessons(newLessons);

                                                        const db = firebase.firestore();
                                                        db.collection("classes").doc(classId).set({
                                                            lessons: newLessons
                                                        }, {merge: true})
                                                            .then(() => {
                                                                onUpdate();
                                                            })
                                                            .catch((error) => {
                                                                console.error("Error setting lessons: ", error);
                                                            });
                                                    }}>
                                                        Check In
                                                    </Button>
                                                    }
                                                </div>
                                                {courseRunSession &&
                                                    <div className="pt-2 mt-auto flex-column d-flex align-items-end">
                                                        <small>Linked to Skillsfuture</small>
                                                        {allowCheckin &&
                                                            <div>[<Button className="p-0" color="link" onClick={() => { setSelectedAttendanceSessionId(courseRunSession.id) }}>Attendance</Button>]</div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                     </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {allowUpdate &&
            <Row className="my-3">
                <Col>
                    <Button color="primary" disabled={updating} onClick={() => {
                        const newLessons = [...lessons];
                        if (newLessons.length > 0) {
                            const lastLesson = newLessons[newLessons.length - 1];
                            const newLesson = {...lastLesson};
                            newLesson.startTime = moment(newLesson.startTime).add(7, 'days').toDate();
                            newLesson.endTime = moment(newLesson.endTime).add(7, 'days').toDate();
                            delete newLesson.checkin; // Do not copy checkin status
                            delete newLesson.timesheetId; // Do not copy timesheet ID
                            newLessons.push(newLesson);
                        } else {
                            const startTime = moment().startOf('hour');
                            const endTime = moment(startTime).add(60, 'minutes');
                            newLessons.push({
                                startTime: startTime.toDate(),
                                endTime: endTime.toDate(),
                                teacher: classDetails.teachers[0],
                                status: 1
                            });
                        }
                        setLessons(newLessons);
                        window.setTimeout(() => {
                            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth'});
                        }, 500);
                    }}>
                        Add Lesson
                    </Button>
                    <Button color="primary" disabled={updating} className="my-3 ml-2" onClick={saveChanges}>
                        {updating &&
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        <span>Save changes</span>
                    </Button>
                </Col>
            </Row>
            }
            <Modal isOpen={!!selectedAttendanceSessionId} toggle={toggleSessionAttendance}>
                <ModalHeader toggle={toggleSessionAttendance}>Attendance</ModalHeader>
                <ModalBody>
                    {courseRun &&
                        <div className="d-flex">
                            <div className="mr-3">
                                <h5>Attendees {selectedAttendanceTrainees ? `(${selectedAttendanceTrainees.length})` : ''}</h5>
                                {!selectedAttendanceTrainees && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                                {selectedAttendanceTrainees && selectedAttendanceTrainees.length === 0 && <div>No attendees</div>}
                                {selectedAttendanceTrainees && selectedAttendanceTrainees.length > 0 && selectedAttendanceTrainees.map((trainees) => (
                                    <div key={trainees}>{trainees}</div>
                                ))}
                            </div>
                            {courseRun.qrCodeLink &&
                            <div className="ml-auto">
                                <a href={courseRun.qrCodeLink} target="_blank" rel="noreferrer noopener">
                                    <QRCode value={courseRun.qrCodeLink} />
                                </a>
                            </div>
                            }
                        </div>
                    }
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default ClassSchedule;
