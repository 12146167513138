import React from "react";
import {Spinner} from "reactstrap";

const Loading = ({ message = 'Loading ...' }) => (
    <div className="my-5 text-center">
        <Spinner color="primary" size="sm" className="mr-1" />{message}
    </div>
);

export default Loading;
