import {useEffect, useState} from "react";
import firebase from "firebase/app";

const useUserOptions = (role) => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();
        db.collection("users").get().then((querySnapshot) => {
            const userDocs = [];
            querySnapshot.forEach((doc) =>{
                userDocs.push(doc.data());
            });
            setUsers(userDocs);
        });
    }, []);

    const options = [];
    users.forEach((user) => {
        if (!role || (user.roles && user.roles.includes(role))) {
            options.push({
                value: user.id,
                label: user.name || user.loginName
            });
        }
        options.sort((a, b) => {
            const nameA = a.label.toUpperCase(); // ignore upper and lowercase
            const nameB = b.label.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
    });
    return options;
};

export default useUserOptions;