import React, {Fragment, useContext, useState} from "react";
import {FaPlus} from "react-icons/fa";
import {NavLink as NavigationLink} from "react-router-dom";
import ReactTable from "react-table";
import {
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
    Progress,
    Button,
    NavLink,
    Form,
    FormGroup,
    Label,
    CustomInput
} from "reactstrap";
import moment from 'moment';
import {Loading} from '../../components';
import {UserContext} from '../../contexts';
import {useClasses, useSettings} from "../../hooks";

const ClassesTable = ({ classes }) => {

    const data = classes.map(({ id, type, curriculum, venue, lessons, teachers, students, totalHours, carryForwardHours, firstLessonStartTime, firstLessonEndTime, lastLessonStartTime, lastLessonEndTime, ssgCourseRunId }) => {

        const title = `${curriculum.label} - ${type.label} `;
        const noOfLessons = lessons.length;

        const expectedScheduledMinutes = (totalHours - carryForwardHours) * 60;
        let scheduledMinutes = 0;
        let pendingLessons = 0, completedLessons = 0;
        lessons.forEach(({startTime, endTime}) => {
            const start = moment(startTime.toDate());
            const end = moment(endTime.toDate());
            const scheduled = end.diff(start, 'minutes');
            scheduledMinutes += scheduled;
            if (end.isAfter()) {
                pendingLessons++;
            } else {
                completedLessons++;
            }
        });

        return {
            id,
            title,
            venue,
            noOfLessons,
            expectedScheduledMinutes,
            scheduledMinutes,
            remainingMinutes: expectedScheduledMinutes - scheduledMinutes,
            firstLessonStartTime : firstLessonStartTime ? firstLessonStartTime.toDate() : null,
            firstLessonEndTime : firstLessonEndTime ? firstLessonEndTime.toDate() : null,
            lastLessonStartTime : lastLessonStartTime ? lastLessonStartTime.toDate() : null,
            lastLessonEndTime : lastLessonEndTime ? lastLessonEndTime.toDate() : null,
            teachers,
            students,
            pendingLessons,
            completedLessons,
            ssgCourseRunId
        }
    }).sort((a, b) => {

        const aInProgress = a.pendingLessons > 0;
        const bInProgress = b.pendingLessons > 0;
        const aToBeScheduled = a.remainingMinutes > 0;
        const bToBeScheduled = b.remainingMinutes > 0;

        if (aInProgress && !bInProgress) { return -1 }
        if (!aInProgress && bInProgress) { return 1 }
        if (aToBeScheduled && !bToBeScheduled) { return -1 }
        if (!aToBeScheduled && bToBeScheduled) { return 1 }

        const aTime = a.firstLessonStartTime ? a.firstLessonStartTime : Number.MAX_SAFE_INTEGER;
        const bTime = b.firstLessonStartTime ? b.firstLessonStartTime : Number.MAX_SAFE_INTEGER;

        return bTime - aTime;
    });

    const columns = [{
        Header: 'Class',
        accessor: 'title',
        minWidth: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const title = cellProps.value;
            const classId = cellProps.original.id;
            const venue = cellProps.original.venue;
            const students = cellProps.original.students;
            const studentNames = students.length > 2 ? `${students.length} students` : students.join(', ');
            const ssgCourseRunId = cellProps.original.ssgCourseRunId
            return (
                <div className="d-flex flex-column align-items-center h-100 p-2 justify-content-center text-center">
                    <div title={title}>
                        <NavLink
                            tag={NavigationLink}
                            to={`/classes/${classId}`}
                        >
                            {title}
                        </NavLink>
                    </div>
                    {!!ssgCourseRunId && <small style={{ marginTop: -10, marginBottom: 10}}><em><strong>Skillsfuture</strong></em></small>}
                    {venue &&
                    <div title={venue}>
                        {venue}
                    </div>
                    }
                    {studentNames &&
                    <div title={studentNames}>
                        <small>{studentNames}</small>
                    </div>
                    }
                </div>
            )
        }
    },{
        Header: 'Date',
        accessor:'firstLessonStartTime',
        minWidth: 150,
        Cell: cellProps => {

            const firstLessonStartTime = cellProps.value;
            const { lastLessonStartTime, pendingLessons, completedLessons, remainingMinutes }  = cellProps.original;
            const remainingHours = `${Math.floor(remainingMinutes / 60)}h ${remainingMinutes % 60 > 0 ? `${remainingMinutes % 60}m`  : ''} `;

            const startDate = firstLessonStartTime ? moment(firstLessonStartTime).format('DD MMMM YYYY') :
                <span className="text-danger">Not scheduled: {remainingHours}</span>
            const endDate = lastLessonStartTime ? moment(lastLessonStartTime).format('DD MMMM YYYY') :
                firstLessonStartTime ? <span className="text-danger">To be scheduled: {remainingHours}</span> : '';

            const title = startDate && endDate ? `${startDate} - ${typeof(endDate) === 'string' ? endDate : '?'}` : null;

            return (
                <div title={title} className="d-flex flex-column align-items-center h-100 p-2 justify-content-center">
                    {pendingLessons > 0 &&
                    <div className="w-100 mb-2">
                        <div className="text-right">
                            {completedLessons} / {completedLessons + pendingLessons}
                            {pendingLessons <= 5 &&
                            <span className="ml-1 text-danger">({pendingLessons} left)</span>
                            }
                        </div>
                        <Progress color={pendingLessons > 5 ? 'success' : 'warning'} value={completedLessons} max={completedLessons + pendingLessons} />
                    </div>
                    }
                    <div>
                        {startDate}
                    </div>
                    <div>
                        {endDate}
                    </div>
                </div>
            );
        }
    },{
        Header: 'Time',
        accessor: 'firstLessonStartTime',
        minWidth: 100,
        Cell: cellProps => {
            const firstLessonStartTime = cellProps.value;
            const lastLessonStartTime = cellProps.original.lastLessonStartTime;
            const regularTime = lastLessonStartTime ? lastLessonStartTime : firstLessonStartTime;
            const startDate = regularTime ? moment(regularTime).format('dddd') : '';
            const startTime = regularTime ? moment(regularTime).format('hh:mm A'): '';
            return (
                <div className="d-flex align-items-center h-100 p-2 justify-content-center">
                    <div title={`${startDate} ${startTime}`} className="text-center">
                        <div>{startDate}</div>
                        <div>{startTime}</div>
                    </div>
                </div>
            )
        }
    },{
        Header: 'Lessons',
        accessor: 'noOfLessons',
        minWidth: 100,
        Cell: cellProps => {
            const noOfLessons = cellProps.value;

            if (noOfLessons === 0) {
                return "";
            }

            const { firstLessonStartTime, firstLessonEndTime } = cellProps.original;
            const startTime = moment(firstLessonStartTime);
            const endTime = moment(firstLessonEndTime);
            const noOfMinutes = endTime.diff(startTime, 'minutes');
            return (
                <div className="d-flex align-items-center h-100 p-2 justify-content-center">
                    <div title={`${noOfLessons} x ${noOfMinutes} mins`} className="text-center">
                        <div>{noOfLessons} x</div>
                        <div>{noOfMinutes} mins</div>
                    </div>
                </div>
            )
        }
    },{
        Header: 'Teachers',
        accessor: 'teachers',
        minWidth: 150,
        style: { 'whiteSpace': 'unset' },
        Cell: cellProps => {
            const teachers = cellProps.value;
            const teachersNames = teachers.map((teacher) => teacher.label);
            const title = teachersNames.join('\n');
            return (
                <div className="d-flex align-items-center h-100 p-2 justify-content-center text-center">
                    <div title={title}>
                        {teachersNames.map((teacherName) => (
                            <div key={teacherName} className="my-1">{teacherName}</div>
                        ))}
                    </div>
                </div>
            )
        }
    }];

    return <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={10}
        sortable={false}
    />

};

const Classes = ({ history }) => {

    const [type, setType] = useState({ value: 'ALL', label: 'ALL'});
    const [curriculum, setCurriculum] = useState({ value: 'ALL', label: 'ALL'});
    const [teacher, setTeacher] = useState({ value: 'ALL', label: 'ALL'});

    const settings = useSettings();

    const user = useContext(UserContext);
    const isClassAdmin = user.hasRole('class-admin');

    let classes = useClasses(false);

    const { teachers, teacherMap } = {
        teachers: [],
        teacherMap: {}
    };
    if (classes) {
        classes.reduce((map, { teachers }) => {
            teachers.forEach((teacher) => {
                if (!map.teacherMap[teacher.value]) {
                    map.teacherMap[teacher.value] = teacher.label;
                    map.teachers.push(teacher);
                }
            });
            return map;
        }, { teachers, teacherMap });
    }
    teachers.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const types = settings.classes ? [...settings.classes.types] : [];
    if (types.length > 0) {
        types.push({
            value: 'ssg-class',
            label: 'Skillfuture Class'
        });
    }

    if (type && type.value !== 'ALL') {
        if (type.value === 'ssg-class') {
            classes = classes.filter((classObj) => {
                return !!classObj.ssgCourseRunId
            });
        } else {
            classes = classes.filter((classObj) => {
                return classObj.type.value === type.value;
            });
        }
    }
    if (curriculum && curriculum.value !== 'ALL') {
        classes = classes.filter((classObj) => {
            return classObj.curriculum.value === curriculum.value;
        });
    }
    if (teacher && teacher.value !== 'ALL') {
        classes = classes.filter((classObj) => {
            const teacherValues = classObj.teachers.map((teacher) => teacher.value);
            return teacherValues.includes(teacher.value);
        });
    }

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag={NavigationLink} to="/">Home</BreadcrumbItem>
                <BreadcrumbItem active tag="span">Classes</BreadcrumbItem>
            </Breadcrumb>
            {isClassAdmin &&
                <Row>
                    <Col className="text-right">
                        <Button color="primary" className="m-3" onClick={() => {
                            history.push({
                                pathname: '/classes/new'
                            });
                        }}><FaPlus className="align-text-top mr-2" size={18} />Add new class</Button>
                    </Col>
                </Row>
            }
            {!classes && <Loading />}
            {classes &&
                <Fragment>
                    <Fragment>
                        <Form>
                            <Row className="mb-2">
                                <Col md={6} lg={4}>
                                    <FormGroup>
                                        <Label for="classType">Type</Label>
                                        <CustomInput value={type.value} type="select" id="classType" name="classType" onChange={(ev) => {
                                            const typeValue = ev.target.value;
                                            setType({
                                                value: typeValue,
                                                label: settings.classes.typeMap[typeValue]
                                            });
                                        }}>
                                            <option value="ALL">ALL</option>
                                            {types.map(({label, value}) => (
                                                <option key={value} value={value}>{label}</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={4}>
                                    <FormGroup>
                                        <Label for="classType">Curriculum</Label>
                                        <CustomInput value={curriculum.value} type="select" id="curriculumType" name="curriculumType" onChange={(ev) => {
                                            const curriculumValue = ev.target.value;
                                            setCurriculum({
                                                value: curriculumValue,
                                                label: settings.curriculum.typeMap[curriculumValue]
                                            });
                                        }}>
                                            <option value="ALL">ALL</option>
                                            {settings.curriculum && settings.curriculum.types.map(({label, value}) => (
                                                <option key={value} value={value}>{label}</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                </Col>
                                <Col md={6} lg={4}>
                                    <FormGroup>
                                        <Label for="teacher">Teacher</Label>
                                        <CustomInput value={teacher.value} type="select" id="teacher" name="teacher" onChange={(ev) => {
                                            const teacherValue = ev.target.value;
                                            setTeacher({
                                                value: teacherValue,
                                                label: teacherMap[teacherValue]
                                            });
                                        }}>
                                            <option value="ALL">ALL</option>
                                            {teachers && teachers.map(({label, value}) => (
                                                <option key={value} value={value}>{label}</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Fragment>
                    <ClassesTable classes={classes} />
                </Fragment>
            }
        </Fragment>
    );
};

export default Classes;
